import { Timestamp } from 'firebase/firestore'
import Address from './Address'
import { CareType } from './Consultation'
import { HealthInstitution } from './HealthInstitution'
export enum AvailabilityStatus {
  CONSULTING = 'CONSULTING',
  AWAY = 'AWAY',
  AVAILABLE = 'AVAILABLE',
}
type MapTypeObj = {
  [id: string]: {
    id: string
    value: boolean
    data?: MapType
  }
}
type MapType = {
  [id: string]: string
}
export interface PaymentMethod {
  id: string;
  authorization_code: string;
  bin: string;
  last4: string;
  exp_month: string;
  exp_year: string;
  card_type: string;
  bank: string;
  country_code: string;
  brand: string;
  account_name: string;
}
export interface ProfileData {
  passCode?: number;
  profileImage: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  address: Address;
  created_at: Timestamp;
  updated_at: Timestamp;
  memberID: string;
  gender: "Male" | "Female";
  fcmToken?: string;
  expoPushToken?: string;
  appointmentCount: number;
  is_primary: boolean;
  accountId: string;
  referrer: string;
  dob: Timestamp;
  activeDeviceId: string;
  completed_assessment: boolean;
  is_student?: boolean;
}
export interface UserData {
  perks: {
    consultation: { VIRTUAL: 0; CONCIERGE: 0; "IN-PERSON": 0 };
    free_asks?: number;
  };
  activeSubscription: Subscription;
  uid: string;
  primary_member_id: string;
  status: UserStatus;
  created_at: Timestamp;
  updated_at: Timestamp;
  balance: {
    currency: string;
    value: number;
    org_value?: number;
  };
  freeConsultations: number;
  orgId: string;
}
export interface Patient extends ProfileData {
  bioData?: {
    bloodType?: string;
    allergies?: string[];
    emergencyContact?: Contact;
    foodAllergies?: string[];
    alcholIntake?: string;
    smoke?: string;
    anxietyFrequency?: string;
    previousDiagnosedConditions?: string[];
    familyPreviousDiagnosedConditions?: string[];
    pastThreeMonthsDiagnoses?: string[];
    consistentMedications?: string[];
    allergicMedications?: string[];
    activityLevel?: string;
  };

  bioMarkers: {
    [key: string]: {
      [key: string]: {
        unit: string;
        value: string;
        timestamp: Timestamp;
      };
    };
  };
  myDoctors: string[];
  savedProviders: string[];
  savedFacilities?: string[];
  preferredPharmacy?: {
    name: string;
    id: string;
    phone: string;
    email: string;
  };
}
export interface Doctor {
  discount?:{
    code: string;
    is_active: boolean;
    value: number;
  };
  practicies?:{
    is_primary: boolean;
    institutionId: string
  }[]
  specialties: string[];
  dob: Timestamp;
  accepts_perks?: boolean;
  accepts_students_package?: boolean;
  profileImage: string;
  id: string;
  isFeatured?: boolean;
  uid: string;
  status: UserStatus;
  created_at: Timestamp;
  updated_at: Timestamp;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  address: Address;
  memberID: string;
  gender: "Male" | "Female";
  fcmToken?: string;
  expoPushToken?: string;
  appointmentCount: number;
  referrer: string;
  bio: string;
  specialty: string;
  is_onboarded: boolean;
  education: {
    graduationDate: Timestamp;
    shoolAddress: string;
    schoolName: string;
    degree: string;
  };
  patients: string[];
  availabilityStatus: AvailabilityStatus;
  unAvailableDateRange: {
    [id: string]: {
      startTime: Timestamp;
      endTime: Timestamp;
      appointmentId: string;
    };
  };
  enabledServices: {
    VIRTUAL: {
      duration?: number;
      enabled: boolean;
      price: number;
      availability: DaySchedule[];
    };
    CONCIERGE: {
      duration?: number;
      enabled: boolean;
      price: number;
      availability: DaySchedule[];
    };
    "IN-PERSON": {
      duration?: number;
      enabled: boolean;
      price: number;
      availability: DaySchedule[];
    };
  };

  onboarding: {
    availability: {
      id: "availability";
      value: boolean;
    };
    agreement: {
      id: "agreement";
      value: boolean;
    };
    nationality: {
      id: "nationality";
      value: boolean;
    };
    basic_info: {
      id: "basic_info";
      value: boolean;
    };
    education: {
      id: "education";
      value: boolean;
    };
    experience: {
      id: "experience";
      value: boolean;
    };
    medical_liscense: {
      id: "medical_liscense";
      value: boolean;
      data: {
        board_council: string;
        dateOfExpirt: Timestamp;
        dateOfIssue: Timestamp;
        documentId: string;
      };
    };
    withdrawal_method: {
      id: "medical_liscense";
      value: boolean;
    };
  };
  awaitingReview?: boolean;
  languages: string[];
  workExperiences: WorkExperienceType[];
}
export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BANNED = 'banned',
}

export interface PractitionerData {
  userId: string
  specialisation: Specialisation
  education: string
  doctorId: string
}
export interface Specialisation {
  title: string
  proficiency: string
  liscence: string
  experience: string
}

interface Contact {
  name: string
  relationship: 'spouse' | 'mother' | 'father' | 'brother' | 'sister' | 'child' | 'friend'
  phoneNumber: string
}
export interface Subscription {
  planId: string
  startDate: Timestamp
  endDate: Timestamp
  credits: number
}

export interface MatchedDoctor extends ReviewedDoctor {
  specialtyData?: Specialty
  isAvailable: boolean
  nextInterval?: AvailabilityIntervals
}
export interface ReviewedDoctor extends Doctor {
  doctorRatings?: {
    overallRating: number
    mannerRating: number
    waitTimeRating: number
    count: number
  }
}
export interface DaySchedule {
 
    intervals: AvailabilityIntervals[];
    day: string;
    type: "wday" | "date";
    date?: Date;
  
}
export interface Specialty {
  id: string;
  title: string;
  short_title: string;
  description: string;
  common_title: string;
  care_type: string;
  care_sub_type: string;
  status: "active" | "inactive";
  credit_amount: number;
  honorific: "string";
  board_council?: {
    title: string;
    url: string;
  };
}
export interface AvailabilityIntervals {
  from: string
  to: string
  day: string
}
export interface WorkExperienceType {
  city: string
  country: string
  jobTitle: string
  companyName: string
  startDate: string
  endDate: string
  type: 'Hospital' | 'Housemanship' | 'Internship'
}

export interface Plan {
  currency: string
  description: string[]
  duration_in_days: number
  external_plan_code: string
  interval: string
  perks: {
    credits: number
  }
  price: number
  title: string
  id: string
  subTitle: string
}

export interface WorkExperience {
  city: string
  companyName: string
  country: string
  endDate: string
  jobTitle: string
  startDate: string
  type: {
    name: string
    value: string
  }
}

export interface Transaction {
  userId: string
  transactionReference: string
  amount: number
  isSubscription: boolean
  createdAt: Timestamp
  status: string
  paymentChannel: string
  paymentMethod: string
  currency: string
  ip_address: string
  id: string
}
export enum VisitType {
  VIRTUAL = 'VIRTUAL',
  CONCIERGE = 'CONCIERGE',
  'IN-PERSON' = 'IN-PERSON',
}

export interface ServiceItem {
  id?: string;
  name: string;
  quantity: number;
  price: number;
  image?: string;
  type: "LAB" | "DIAGNOSTIC";
}