import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { toast } from "react-toastify";
import { Edit, X } from "react-feather";
import { RotatingLines } from "react-loader-spinner";
import { CountryType } from "../../constants/countries";
import {
  useSendOtp,
  useUserAuth,
  useVerifyOtp,
} from "../../state/features/authSlice/hooks";
// import CountryDropdown from "../CountryDropdown";
import OtpInputForm from "../OtpInput";
import { DDGColors } from "../../constants/colors";

interface StepOneProps {
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  countryCode: CountryType;
  setCountryCode: React.Dispatch<React.SetStateAction<CountryType>>;
  callbackFunc: (step: number) => void;
  phone: string;
}

export function StepOne(props: StepOneProps) {
  const {
    countryCode,
    phoneNumber,
    setCountryCode,
    setPhoneNumber,
    callbackFunc,
    phone,
  } = props;
  const sendOTP = useSendOtp();
  const verifyOtp = useVerifyOtp();
  const [otpProvider, setOtpProvider] = useState<any>("");
  const { loading, login, loginWithCredentials } = useUserAuth();
  const [phoneVerificationLoading, setPhoneVerificationLoading] =
    useState(false);
  const [requestId, setRequestId] = useState<string>("");
  const [prefix, setPrefix] = useState<string>();
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [otp, setOtp] = useState<string[]>(new Array(5).fill(""));
  const [phoneMode, setPhoneMode] = useState("");
  const [userExist, setUserExist] = useState(false);
  const [password, setPassword] = useState("");
  const handleSendOTP = async () => {
    try {
      setOtpProvider("");
      setPhoneVerificationLoading(true);
      sendOTP(
        {
          countryCode: countryCode.code,
          phoneNumber,
          checkUserExist: true,
        },
        (data) => {
          if (data) {
            if (data.userExists) {
              setUserExist(data.userExists);
              setOtpProvider(data.provider);
              setPrefix(data?.prefix);
              setRequestId(data?.requestId);
              setPhoneVerificationLoading(false);
            } else {
              callbackFunc(1);
            }
          }
        }
      );
    } catch (error) {
      setPhoneVerificationLoading(false);
      console.error("Error occured", error);
    }
  };
  const handleVerifyOTP = async () => {
    try {
      setVerifyingOtp(true);
      verifyOtp(
        {
          requestId: otpProvider === "ARKESEL" ? `${phoneNumber}` : requestId,
          ...(otpProvider === "HUBTEL" && { prefix }),
          code: otp.join(""),
          provider: otpProvider,
          isPhoneSignIn: {
            phoneNumber: `${phoneNumber}`,
          },
        },
        async (data: { status: string; customToken?: string }) => {
          if (data.status === "SUCCESS") {
            if (data.customToken && data.customToken !== "ERROR") {
              loginWithCredentials(
                {
                  customToken: data.customToken,
                },
                () => {
                  toast.success("Phone number verified 👍");
                  callbackFunc(2);
                  setVerifyingOtp(false);
                }
              );
            } else {
              callbackFunc(1);
            }
          }
          setVerifyingOtp(false);
        }
      );
    } catch (err: any) {
      setVerifyingOtp(false);
      toast.error(`Error: ${err.message}`);
    }
  };
  return (
    <div>
      <div className="my-4">
        <p className="text-lg font-bold text-gray900">
          Let’s Complete your Booking Process
        </p>
        <p className="text-xs text-gray500">
          Please enter your phone number to proceed. We&apos;ll send you an OTP
          to verify your number.
        </p>
      </div>
      <div>
        <p className="text-[10px] font-semibold text-gray700">Phone Number</p>
        <div
          className={`rounded-md border-[#D0D5DD] px-2 mt-2 flex flex-row border ${
            otpProvider ? "bg-[#F2F2F2]" : "bg-transparent"
          }`}
        >
          <div className="flex pr-3">
            <div className="flex items-center justify-between gap-3 space-x-3">
              <span className="inset-y-0 left-0 flex items-center text-primary">
                <img
                  loading="lazy"
                  height={40}
                  width={20}
                  src={`https://flagcdn.com/w20/gh.png`}
                  alt=""
                  className="object-contain "
                />
              </span>
              <span className={`block text-left truncate text-[#9ca3af]`}>
                +233
              </span>
            </div>
          </div>
          <div className="border-l border-[#D0D5DD] py-2 flex flex-1 flex-row items-center justify-between">
            <input
              disabled={otpProvider}
              className={`pl-4 text-xs outline-none ${
                otpProvider ? "bg-[#F2F2F2]" : "bg-transparent"
              }`}
              value={phone}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              placeholder="Phone number"
            />
            {otpProvider && (
              <button
                onClick={() => {
                  setOtpProvider("");
                  setPhoneMode("");
                  setUserExist(false);
                }}
                className="self-end p-1 bg-gray-200 rounded-full"
              >
                <Edit size={14} />
              </button>
            )}
          </div>
        </div>
      </div>
      {!phoneMode && userExist && (
        <div className="flex flex-row items-center mt-4 space-x-2">
          <div className="w-1/2">
            <button
              onClick={() => {
                setPhoneMode("password");
              }}
              className="items-center justify-center w-full h-10 bg-gray-100 rounded-lg"
            >
              <p className="text-xs font-bold">Continue with password</p>
            </button>
          </div>
          <div className="w-1/2">
            <button
              onClick={() => {
                setPhoneMode("otp");
              }}
              className="flex-row items-center justify-center w-full h-10 space-x-1 bg-gray-100 rounded-lg"
            >
              <p className="text-xs font-bold">Continue with OTP</p>
              {/* {sendingOtp && (
                        <RotatingLines width="20" strokeColor={DDGColors.dark} />
                      )} */}
            </button>
          </div>
        </div>
      )}
      <div className="mt-4">
        {" "}
        {phoneMode === "otp" ? (
          <div className="flex flex-col mt-4">
            <p className="text-[10px] font-semibold text-gray700">Enter OTP</p>
            {otpProvider === "ARKESEL" && (
              <p className="text-xs text-gray-500">
                Dial
                <span className="text-sm font-bold text-blood">
                  {" "}
                  *928*01#
                </span>{" "}
                if you do not get the otp
              </p>
            )}
            <div className="">
              <OtpInputForm
                otp={otp}
                setOtp={setOtp}
                length={otpProvider === "ARKESEL" ? 6 : 4}
                onSubmit={(otp) => console.log(otp)}
                alignLeft
              />
            </div>
            <button
              className="self-center px-3 py-1"
              onClick={() => {
                handleSendOTP();
              }}
            >
              <h4 className="text-[10px] font-bold text-red-400">Resend OTP</h4>
            </button>
          </div>
        ) : phoneMode === "password" ? (
          <div>
            <p className="text-[10px] font-semibold text-gray700">Password</p>
            <input
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
              className="border text-gray500 w-full text-xs py-2 rounded-md shadow-sm border-[#D0D5DD] px-2 mt-2 outline-none"
              placeholder="Password ****"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <button
        // disabled={loading || phoneVerificationLoading}
        onClick={() => {
          phoneMode === "otp"
            ? handleVerifyOTP()
            : phoneMode === "password"
            ? login(
                {
                  phoneNumber,
                  password,
                },
                () => {
                  toast.success("Phone number verified 👍");
                  callbackFunc(2);
                  setVerifyingOtp(false);
                }
              )
            : handleSendOTP();
        }}
        className="flex items-center justify-center w-full py-3 mt-8 text-xs font-bold text-white rounded-md bg-primary"
      >
        {loading || phoneVerificationLoading || verifyingOtp ? (
          <RotatingLines width="20" strokeColor={DDGColors.gainsboro} />
        ) : (
          <p className="text-xs font-semibold text-white">Continue</p>
        )}
      </button>
    </div>
  );
}
