import React, { useState, useEffect, useMemo } from "react";
import { StepOne } from "./StepOne";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { StepTwo } from "./StepTwo";
import StepThree from "./StepThree";
import { AppState } from "../../state";
import { useUserAuth } from "../../state/features/authSlice/hooks";
import { CountryType } from "../../constants/countries";
import Progress from "../Progress";
import ServiceBookingReview from "./ServiceStepBooking";

function BookingSteps({ type }: { type: 'DOCTOR_BOOKING' | 'SERVICE_BOOKING' }) {
  const { User, Account } = useSelector<AppState, AppState["auth"]>(
    (state) => state.auth
  );
  const { activeDoctor, activeFacility } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );
  const [activeStep, setActiveStep] = useState(0);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  // AnonymousEntry CREDENTIALS
  const [phone, setPhone] = useState<string>("");

  const {loading, createPatient, loginWithCredentials} = useUserAuth();
  const [countryCode, setCountryCode] = useState<CountryType>({
    code: "GH",
    label: "Ghana",
    phone: "+233",
  });
  const phoneNumber = useMemo(() => {
    return countryCode.phone + parseInt(phone.replaceAll(" ", ""), 10).toString();
  }, [countryCode.phone, phone]);
  useEffect(() => {
    if (Account) {
      setActiveStep(2);
    }
  }, []);
  const REGISTERATION_STEPS = [
    {
      title: "Step 1",
      subTitle: "Verify Account",
      component: (
        <StepOne
          countryCode={countryCode}
          phoneNumber={phoneNumber}
          phone={phone}
          setCountryCode={setCountryCode}
          setPhoneNumber={setPhone}
          callbackFunc={() => {
            setActiveStep(activeStep + 1);
          }}
        />
      ),
    },
    {
      title: "Step 2",
      subTitle: "Basic Details",
      component: (
        <StepTwo
         loading={loading}
         createAccount={async(data)=>{
         createPatient({ ...data, phoneNumber, quickSignIn: !data.password }, () => {
             toast.success('Welcome 👍');
           })
         }}
        />
      ),
    },
  ];

  return (
    <div className={`flex flex-col px-2 flex-1`}>
      {!Account ? (
        <>
          {/* <Stepper activeStep={activeStep} className={`w-full`}>
            {REGISTERATION_STEPS.map((step) => (
              <Step
                key={step.title}
                sx={{ color: "teal", outlineColor: "red" }}
              >
                <StepLabel className="">
                  <p>{step.title}</p>
                  <p className=" text-[8px]  text-gray-700 l">
                    {step.subTitle}
                  </p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>{" "} */}
          <div className="flex justify-end "><div className="flex justify-center w-1/3">
            <Progress color="#034a47" progress={activeStep / 2} />
          </div></div>
          <form onSubmit={(e) => e.preventDefault()} className="px-2 my-4">
            {REGISTERATION_STEPS[activeStep].component}
          </form>
        </>
      ) :(type === 'DOCTOR_BOOKING' && activeDoctor ? (
        <StepThree activeDoctor={activeDoctor.doctor} callbackFunc={()=>{
          setShowSuccessScreen(true)
        }}/>
      ): type === 'SERVICE_BOOKING' && activeFacility ? (
        <ServiceBookingReview />
      ): <></>)}
    </div>
  );
}

export default BookingSteps;
