import React from "react";
import { DDGColors } from "./colors";
import { VisitType } from "../types/User";
import { Home, MapPin, Video } from "react-feather";

export const visitTypes: {
    name: string;
    value: VisitType;
    icon: (val: boolean)=> JSX.Element;
  }[] = [
    {
      name: "Virtual Visit",
      value: VisitType.VIRTUAL,
      icon: (isActive)=> <Video size={10} color={isActive ?DDGColors.primaryLight:DDGColors.dark} />,
    },
    {
      name: "Home Visit",
      value: VisitType.CONCIERGE,
      icon:(isActive)=> (
        <MapPin
          size={10}
          color={isActive ?DDGColors.primaryLight:DDGColors.dark}
        />
      ),
    },
    {
      name: "Health Facility Visit",
      value: VisitType["IN-PERSON"],
      icon: (isActive)=> (
        <Home size={10} color={isActive ?DDGColors.primaryLight:DDGColors.dark} />
      ),
    },
  ];
  export const AppConfig = {
    title: 'Drdogood',
    description: 'Talk to a doctor, therapist or medical expert anywhere you are by phone or video.',
    locale: 'en',
    siteName: 'DrDoGood',
  }
  