import Home from "./home";
import SpeaktoDoctor from './speakToDoctor'
import { useSelector } from "react-redux";
import { AppState } from "../state";
import { ApplicationPages } from "../state/features/applicationSlice";
import DoctorIntro from "./speakToDoctor/doctor";
import Start from "./speakToDoctor/start";
import Success from "./success";
import ServiceStart from "./bookServices/start";
import ServiceSuccess from "./success/ServiceSuccess";

export const Views = ({isPopUp}:{isPopUp: boolean}) => {
  const { activePage } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );
  return (
    <div className="flex flex-col min-h-[700px] flex-1 h-full">
      {activePage === ApplicationPages.HOME && <Home isPopUp={isPopUp}/>}
      {activePage === ApplicationPages.SPEAK_TO_DOCTOR && <SpeaktoDoctor />}
      {activePage === ApplicationPages.DOCTOR && <DoctorIntro />}
      {activePage === ApplicationPages.START && <Start />}
      {activePage === ApplicationPages.SUCCESS && <Success />}
      {activePage === ApplicationPages.BOOKING_SERVICE_START && <ServiceStart />}
      {activePage === ApplicationPages.BOOKING_SERVICE_SUCCESS && <ServiceSuccess />}
    </div>
  );
};
