import { initializeApp } from 'firebase/app'
import { getFirestore, initializeFirestore } from 'firebase/firestore'
import { getAnalytics, isSupported, logEvent } from 'firebase/analytics'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

export const geoFire = require('geofire-common')
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}
const firebaseApp = initializeApp(firebaseConfig)

let analytics
let messaging
if (typeof window !== 'undefined') {
  try {
    analytics = getAnalytics(firebaseApp)
    messaging = getMessaging(firebaseApp)
  } catch (error) {
    console.log('error', error)
  }
}

const db = getFirestore(firebaseApp)
export { db, analytics, messaging }