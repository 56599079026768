// import { addDays, format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { MatchedDoctor } from "../../types/User";
import { AppState } from "../../state";
import {
  addDays,
  convertDateToString,
  filteredIntervals,
  getAvailableIntervals,
  getNextPossibleInterval,
  getNextWeekday,
} from "../../functions";
import { setActiveBooking } from "../../state/features/applicationSlice";
import { visitTypes } from "../../constants/constant";
interface ProviderAvailabilityProps {
  activeDoctor: MatchedDoctor;
}

const ProviderAvailability: React.FC<ProviderAvailabilityProps> = ({
  activeDoctor,
}) => {
  const [viewAll, setViewAll] = useState(false);
  const { activeBooking } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );
  const [activeDay, setActiveDay] = useState(new Date());

  const dispatch = useDispatch();
  useEffect(() => {
    setActiveDay(
      getNextWeekday(
        getNextPossibleInterval(
          activeDoctor.enabledServices[activeBooking.visitType.value]
            .availability,
          activeDoctor.enabledServices[activeBooking.visitType.value].duration
        )?.day ?? ""
      )
    );
  }, [activeDoctor.enabledServices]);
  const intervalTimeSlots = useMemo(() => {
    const availabilityIntervals =
      getAvailableIntervals(
        activeDoctor.enabledServices[activeBooking.visitType.value]
          .availability,
        activeDoctor.enabledServices[activeBooking.visitType.value].duration,
        activeDay
      ) ?? [];
    return availabilityIntervals;
  }, [activeBooking.visitType.value, activeDay, activeDoctor.enabledServices]);
  const handleNextDatesClick = () => {
    setActiveDay(addDays(activeDay, 1));
  };

  const handlePrevDatesClick = () => {
    if (activeDay.getDate() === new Date().getDate()) return;
    setActiveDay(addDays(activeDay, -1));
  };
  return (
    <div>
      {activeBooking.visitType.value && (
        <>
          <div className="flex flex-row items-center justify-between">
            <div className="">
              <div className="flex flex-row items-center my-2">
                <div>
                  <p className="text-xs font-bold text-dark">
                    {/* {format(activeDay, "eee, MMM dd")} */}
                    {convertDateToString(
                      new Date(activeBooking.startTime ?? 0)
                    )}
                  </p>
                </div>
                <div className="flex gap-x-3">
                  <button
                    className="p-1"
                    // disabled={displayedDatesIndex === 0}
                    onClick={handlePrevDatesClick}
                  >
                    <ChevronLeft size={16} />
                  </button>

                  <button
                    className="p-1"
                    onClick={handleNextDatesClick}
                    // disabled={displayedDatesIndex === totalDates - 1}
                  >
                    <ChevronRight size={16} />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-1">
              {visitTypes
                .filter(
                  (type) => activeDoctor?.enabledServices[type.value].enabled
                )
                .map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={`flex items-center h-7 w-7  justify-center rounded-full ${
                        activeBooking.visitType.value === item.value
                          ? "bg-primary"
                          : "bg-gray-100"
                      }`}
                      onClick={() => {
                        dispatch(
                          setActiveBooking({
                            ...activeBooking,
                            visitType: {
                              name: item.name,
                              value: item.value,
                            },
                            startTime: null,
                          })
                        );
                      }}
                    >
                      {item.icon(activeBooking.visitType.value === item.value)}
                    </button>
                  );
                })}
            </div>
          </div>
          <div className="flex flex-row flex-wrap mt-3">
            {intervalTimeSlots.length > 0 &&
              [...intervalTimeSlots]
                .filter((interval) =>
                  filteredIntervals(activeDay, activeDoctor, interval)
                )
                .slice(
                  0,
                  viewAll
                    ? intervalTimeSlots.filter((interval) =>
                        filteredIntervals(activeDay, activeDoctor, interval)
                      ).length
                    : 12
                )
                .map((interval, index) => {
                  const startTime = new Date(
                    activeDay.setHours(
                      Number(interval.split(":")[0]),
                      Number(interval.split(":")[1].split(" ")[0]),
                      0,
                      0
                    )
                  );

                  return (
                    <button
                      onClick={() => {
                        if (
                          new Date(
                            activeBooking?.startTime ?? new Date()
                          )?.getTime() == startTime.getTime()
                        ) {
                          dispatch(
                            setActiveBooking({
                              ...activeBooking,
                              startTime: null,
                            })
                          );
                        } else {
                          dispatch(
                            setActiveBooking({
                              ...activeBooking,
                              startTime: startTime.getTime(),
                            })
                          );
                        }
                      }}
                      key={index}
                      className="flex items-center justify-center w-1/3 md:w-[30%] p-1 "
                    >
                      <div
                        className={`rounded-md flex items-center justify-center w-full px-2 border-[0.5px] border-[#D6D6D6] py-1.5 space-x-2 cursor-pointer md:px-4 ${
                          new Date(
                            activeBooking?.startTime ?? new Date()
                          ).getTime() == startTime?.getTime()
                            ? " bg-primary"
                            : "bg-[#F7F7F7]"
                        }`}
                      >
                        <p
                          className={`text-[11px]  ${
                            new Date(
                              activeBooking?.startTime ?? new Date()
                            )?.getTime() == startTime?.getTime()
                              ? " text-primaryLight"
                              : "text-gray900"
                          }`}
                        >
                          {interval}{" "}
                          {parseFloat(interval.split(":")[0]) > 11
                            ? "PM"
                            : "AM"}
                        </p>
                      </div>
                    </button>
                  );
                })}
            {intervalTimeSlots.filter((interval) =>
              filteredIntervals(activeDay, activeDoctor, interval)
            ).length > 11 && (
              <div className="flex items-center justify-center w-full p-1 ">
                <button
                  onClick={() => setViewAll(!viewAll)}
                  className="flex items-center justify-center w-full py-2 space-x-2 cursor-pointer  rounded-md border-[0.5px] border-[#D6D6D6] "
                >
                  <p className="text-xs text-gray900">
                    View {viewAll ? "less" : "all"} availability
                  </p>
                </button>
              </div>
            )}
          </div>
        </>
      )}
      {/* <LocalMiscModal
        position="flex-end"
        isOpen={openModal}
        setOpen={setOpenModal}
      >
        <div className="h-auto bg-white rounded-t-2xl">
          <div className="flex-row items-center justify-between bg-white rounded-t-2xl">
            <button
              className="relative p-4"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <MaterialCommunityIcons name="chevron-left" size={24} />
            </button>
            <div className="flex-1">
              <p className="text-sm font-bold">Select a type of visit</p>
            </div>
            <div className="flex-row ">
              <button
                onClick={() => {
                  dispatch(
                    setActiveBooking({ ...activeBooking, visitType: null })
                  );
                  setOpenModal(false);
                }}
                className="p-4"
              >
                <p className="font-bold text-blood">Clear Selection</p>
              </button>
            </div>
          </div>

          <div className="px-4 mb-8">
            <FlatList
              data={visitTypes.filter(
                (type) => activeDoctor?.enabledServices[type.value].enabled
              )}
              renderItem={({ item }) => (
                <button
                  className="flex-row items-center pb-4 my-2 border-b-0.5 border-gray-300"
                  onClick={() => {
                    dispatch(
                      setActiveBooking({
                        ...activeBooking,
                        visitType: {
                          name: item.name,
                          value: item.value,
                        },
                        startTime: null,
                      })
                    );
                    selectedIntervalTimeSlot(null);
                    setOpenModal(false);
                  }}
                >
                  <p
                    className={`${
                      activeBooking.visitType?.value === item.value
                        ? "font-bold"
                        : "font-normal"
                    }`}
                  >
                    {item.name}
                  </p>
                </button>
              )}
            />
          </div>
        </div>
      </LocalMiscModal> */}
      {/* <AppointmentScheduler
          medium={activeBooking.visitType?.value || "VIRTUAL"}
          setMedium={(val) => {
            dispatch(
                setActiveBooking({ ...activeBooking, visitType: visitTypes.find((type) => type.value === val) })
              );
          }}
          activeDoctor={activeDoctor}
          setConsultationStartTime={(startTime)=>{
            dispatch(
                setActiveBooking({ ...activeBooking, startTime: startTime })
              );
          }}
          consultationStartTime={activeBooking.startTime as any}
        /> */}
    </div>
  );
};

export default ProviderAvailability;
