import { FC, useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { DDGColors } from "../../constants/colors";
import {
  ArrowLeft,
  ArrowRight,
  Book,
  Check,
  ChevronLeft,
  ChevronRight,
  Eye,
  Heart,
  Loader,
  Map,
  MapPin,
  PlusCircle,
  Radio,
  Search,
  Share,
  Star,
} from "react-feather";
import { AppState } from "../../state";
import { useSelector } from "react-redux";
import { useSwitchPage } from "../../state/features/applicationSlice/hooks";
import { ApplicationPages } from "../../state/features/applicationSlice";
import Image from "../../components/Image";

interface homeProps {}

const Home: FC<homeProps> = ({}) => {
  const { specialties } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );
  const {
    activeFacility: hospital,
    institutionDoctors,
  } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );
  const router = useSwitchPage();
  const [activeDoctor, setActiveDoctor] = useState("");
  const params = new URLSearchParams(window.location.search);
  if (!hospital) {
    return (
      <div style={{minHeight : 500}} className="relative flex items-center justify-center flex-col flex-grow w-full h-full max-w-lg mx-auto overflow-y-hidden min-h-[300px]">
        <RotatingLines width="20" strokeColor={DDGColors.gainsboro} />
      </div>
    );
  }
  return (
    <main className="relative flex flex-col flex-1 h-screen overflow-y-scroll md:h-full ">
      <div className="flex flex-col mb-4">
        <div className="py-4 pt-8 md:pt-4  pr-4 bg-[#F6F9F9]">
          <div className="flex flex-row items-center ">
            <button
              onClick={() => {
                params.delete('id')
                params.delete('id')
                router(ApplicationPages.HOME);
              }}
              className="p-3 rounded-full"
            >
              <ArrowLeft color="#292D32" size={14} />
            </button>
            <div className="relative flex flex-row items-center justify-center flex-shrink-0 w-8 h-8 mr-2 text-sm text-white rounded-full cursor-pointer bg-primary">
              <Image
              useBase={false}
                className="flex-1 w-full h-full bg-white border rounded-full p-dark border-primaryLight"
                src={hospital.details.logoImage ?? ''}
                alt={hospital.details.institutionName}
              />
            </div>
            <div className="flex-1">
              <p className="text-sm font-bold text-left capitalize">
                {hospital.details.institutionName}
              </p>
              <div className="flex flex-row items-center">
                <p className="text-[10px] text-[#667085]">
                  Medical Consultations
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-end space-x-2">
              <Star
                size={12}
                color={DDGColors.primary}
                fill={DDGColors.primary}
              />
              <p className="text-xs font-bold">5.0</p>
            </div>
          </div>
        </div>
        <div className="flex-1 pt-4 bg-white rounded-t-xl">
          <div className="px-4 ">
            <p className="pb-4 text-xs font-semibold text-gray700">
              Speak to a doctor
            </p>
            <button
              onClick={() => {
                router(ApplicationPages.SPEAK_TO_DOCTOR);
              }}
              className="flex flex-row items-center w-full"
            >
              <div className="p-2 h-8 w-8 bg-[#F0FFFE] rounded-full">
                <Image useBase src="/icons/user-search.svg" alt="" />
              </div>
              <div className="flex-1 px-3 text-left ">
                <p className="font-semibold text-[#101828] text-xs">
                  Help me get a doctor
                </p>
                <p className="text-[9px] text-[#667085]">
                  We would Match You with the Perfect Doctor for Your need.
                </p>
              </div>
              <button className="self-end p-2 rounded-full">
                <ChevronRight className="text-gray700" />
              </button>
            </button>
          </div>
          <div className="px-4 py-8">
            <p className="pb-4 text-xs font-semibold text-gray700">
              Select Your Preferred Medical Professional
            </p>
            <div className="space-y-6">
              {[...institutionDoctors].map((doctor) => {
                return (
                  <button
                  key={doctor.uid}
                    onClick={async() => {
                         params.set("id", activeDoctor)
                    setActiveDoctor(doctor.uid)
                    }}
                    className="flex flex-row w-full"
                  >
                    <div className="relative flex flex-row items-center justify-center flex-shrink-0 mr-3 text-sm text-white rounded-full cursor-pointer w-7 h-7 bg-primary">
                      <Image
                      useBase={false}
                        className="flex-1 w-full h-full bg-white border rounded-full p-dark border-primaryLight"
                        src={doctor.profileImage}
                        alt={doctor.firstName}
                      />
                    </div>
                    <div className="flex flex-row items-center flex-1 pb-4 border-b">
                      <div className="flex-1 pr-3 text-left ">
                        <div className="flex flex-row items-center space-x-2">
                          <div><p className="font-semibold text-[#101828] text-xs">
                            {specialties.find((specialty) => specialty.id === doctor.specialty)?.honorific ?? ''} {doctor.firstName} {doctor.lastName}
                          </p></div>
                        </div>
                        <p className="text-xs text-[#667085]">
                      {specialties.find((specialty) => specialty.id === doctor.specialty)?.title}
                        </p>
                      </div>
                      <button className={`flex items-center self-end justify-center w-5 h-5  rounded-full ${activeDoctor === doctor.uid ? 'bg-primary': 'border border-gray-200'}`}>
                       {activeDoctor === doctor.uid &&  <Check size={10} color="white"/>}
                      </button>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{  backgroundColor:'rgba(250, 250, 250,0.5)' }}
        className={` ${activeDoctor? "flex" : "hidden"}
      duration-400 px-4  w-full self-stretch  py-8   text-dark `}
      >
        <button
          className="items-center justify-center w-full p-3 rounded-md bg-primary"
          onClick={async () => {
        //    router("/start");
        params.set("id", activeDoctor)
        window.history.pushState(null,'', "?"+ params.toString());

        router(ApplicationPages.DOCTOR)
          }}
        >
          <p className="text-xs font-bold text-primaryLight">
           Continue
          </p>
        </button>
      </div>
    </main>
  );
};

export default Home;

// const specialtyIcon = (specialty) => {
//   switch (specialty) {
//     case "Dentistry":
//       return (
//         <FontAwesome5 color={DDGColors.primary} name="teeth" size={18} />
//       );
//     case "Neurology":
//       return (
//         <FontAwesome5 color={DDGColors.primary} name="brain" size={18} />
//       );
//     case "Optometry/Ophthalmology":
//       return <Eye color={DDGColors.primary} name="eye" size={18} />;
//     case "Pediatric":
//       return <Babuy color={DDGColors.primary} name="baby" size={18} />;
//     case "Obstetrics/Gynecology":
//       return (
//         <MaterialCommunityIcons
//           color={DDGColors.primary}
//           name="mother-nurse"
//           size={18}
//         />
//       );
//     case "Primary Care":
//       return (
//         <MaterialCommunityIcons
//           color={DDGColors.primary}
//           name="mother-nurse"
//           size={18}
//         />
//       );
//     case "Cardiology":
//       return (
//         <FontAwesome5 color={DDGColors.primary} name="heart" size={18} />
//       );
//     default:
//       return (
//         <MaterialIcons
//           color={DDGColors.primary}
//           name="health-and-safety"
//           size={18}
//         />
//       );
//   }
// };
