import { collection, doc, getDoc, getDocs, limit, onSnapshot, query, where } from "firebase/firestore"
import { appointmentsCollections, consultationsCollection, doctorsCollection, firebaseBaseUrl, healthInstitutionCollection, profilesCollection, reviewsCollection, usersCollection } from "../constants/firebase"
import { Doctor, MatchedDoctor, Patient, ReviewedDoctor, Specialty, UserData } from "../types/User"
import { Appointment, Review } from "../types/Consultation"
import { FacilityProvider, HealthInstitution, Location, Service } from "../types/HealthInstitution"
import { db } from "../config/firebase"
import axios from "axios"


export async function getPublicDoctorById(id: string) {
    const doctorsRef = collection(db, doctorsCollection)
    const docSnap = await (await getDocs(query(doctorsRef, where('memberID', '==', id), limit(1)))).docs.map(async(doctor)=>{
      const data = { uid: doctor.id, id: doctor.id, ...doctor.data() } as Doctor
      const doctorSpecialty = await getDoc(doc(db, 'practitioners', data.specialty))
      return {...data, specialtyData: doctorSpecialty.data() as Specialty, isAvailable: true}
    })
    return docSnap[0]
  }
  export async function getDoctorReviews(id: string) {
    const reviews: Review[] = []
    const reviewsRef = collection(db, reviewsCollection)
    const docSnap = await getDocs(query(reviewsRef, where('doctorId', '==', id)))
    docSnap.forEach((doctor) => {
      reviews.push({ id: doctor.id, ...doctor.data() } as Review)
    })
    return reviews
  }
  export const getRatings = (reviews: Review[]) => {
    const numOfReviews = reviews.length
    const overallRating = reviews.reduce((acc, currentValue) => acc + currentValue.rating, 0)
    const mannerRating = reviews.reduce((acc, currentValue) => acc + (currentValue?.mannerRating ?? 0), 0)
    const waitTimeRating = reviews.reduce((acc, currentValue) => acc + (currentValue?.waitTimeRating ?? 0), 0)
    return {
      overallRating: Number((overallRating ? overallRating / numOfReviews : 0).toFixed(2)),
      mannerRating: Number((mannerRating ? mannerRating / numOfReviews : 0).toFixed(2)),
      waitTimeRating: Number((waitTimeRating ? waitTimeRating / numOfReviews : 0).toFixed(2)),
      count: numOfReviews,
    }
  }
  export async function getUserData(uid: string) {
    const docRef = doc(db, usersCollection, uid)
    const docSnap = await getDoc(docRef)
    const userProfile = docSnap.data() as UserData
    return userProfile
  }
  
  export async function getUserProfileData(uid: string, memberId: string) {
    const docRef = doc(db, usersCollection, uid, profilesCollection, memberId)
    const docSnap = await getDoc(docRef)
    const userProfile = docSnap.data() as Patient
    return userProfile
  }

  export function appointmentListener(consultationId: string, appointmentId: string, callback:(val: Appointment)=> void) {
    return onSnapshot(doc(db, consultationsCollection, consultationId, appointmentsCollections, appointmentId), (doc) => {
      const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'
      callback({
        id: doc.id,
        ...doc.data(),
      } as unknown as Appointment)
    })
  }
  export function doctorListener(uid: string, callback:(val: Doctor)=> void) {
    return onSnapshot(doc(db, doctorsCollection, uid), (doc) => {
      const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'
      callback({
        id: doc.id,
        ...doc.data(),
      } as unknown as Doctor)
    })
  }
  export function facilityListener(uid: string, callback:(val: HealthInstitution)=> void) {
    return onSnapshot(doc(db, healthInstitutionCollection, uid), (doc) => {
      const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'
      callback({
        id: doc.id,
        ...doc.data(),
      } as unknown as HealthInstitution)
    })
  }
  export async function getfacilityById(id: string) {
    const doctorsRef = doc(db, healthInstitutionCollection, id)
    const facility = (await getDoc(doctorsRef)).data();
    return facility
  }
  export async function getfacilityBySlug(slug: string) {
    const facilitesDocs = await getDocs(query(collection(db, healthInstitutionCollection), where('details.slug', '==', slug), limit(1)))

    return facilitesDocs.docs[0].data();
  }
  export async function getInstitutionServices(institutionId: string) {
    try {
      const services: Service[] = [];
      const docQuery = await query(
        collection(db, healthInstitutionCollection, institutionId, "Services")
      );
      const docs = await getDocs(docQuery);
      docs.forEach((doc) => {
        services.push(doc.data() as Service);
      });
      return services;
    } catch (error) {
      console.error("error in getInstitutionServices", error);
    }
  }
  export async function getInstitutionDoctors(institutionId: string) {
    try {
      const { data } = await axios.get(
        `${firebaseBaseUrl}/institutionalt/get-providers?facilityId=${institutionId}`,
      )
      return(Object.values(data.data.activeDoctors as Doctor))
    } catch (error) {
      console.error("error in getInstitutionDoctors", error);
    }
  }
  export async function getInstitutionLocations(institutionId: string) {
    const locations: Location[] = [];
    const docQuery = await query(
      collection(db, healthInstitutionCollection, institutionId, "Locations")
    );
    const docs = await getDocs(docQuery);
    docs.forEach((doc) => {
      locations.push({ id: doc.id, ...doc.data() } as unknown as Location);
    });
    return locations;
  }