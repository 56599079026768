import React from "react";

interface ProgressProps {
  progress: number;
  color?: string;
}

const Progress: React.FC<ProgressProps> = ({ progress, color }) => {
  return (
    <div className="w-full h-1 bg-gray-300 rounded-full">
      <div
        style={{ width: `${progress * 100}%` }}
        className={`bg-[${color ?? "gray-500"}] h-1 rounded-full`}
      />
    </div>
  );
};

export default Progress;
