const usersCollection = 'Users',
  doctorsCollection = 'Doctors',
  profilesCollection = 'Profiles',
  patientsCollection = 'Patients',
  reviewsCollection = 'Reviews',
  healthInstitutionCollection = 'health_institutions',
  consultationsCollection = 'Consultations',
  appointmentsCollections = 'Appointments',
  messagesCollection = 'Messages',
  firebaseBaseUrl = process.env.REACT_APP_BASE_URL,
  fireStorageUrl = process.env.REACT_APP_FIRE_STORAGE_BASE

export {
  doctorsCollection,
  patientsCollection,
  profilesCollection,
  reviewsCollection,
  usersCollection,
  healthInstitutionCollection,
  appointmentsCollections,
  consultationsCollection,
  messagesCollection,
  firebaseBaseUrl,
  fireStorageUrl,
}
