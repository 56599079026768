import { useCallback } from "react";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { appointmentsCollections, consultationsCollection, firebaseBaseUrl } from "../../../constants/firebase";
import { axiosClient } from "../../../config/axiosClient";
import { db } from "../../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationPages, setActivePage, setCart, setSpecialties } from ".";
import axios from "axios";
import { Specialty } from "../../../types/User";
import { AppState } from "../..";
import { Service } from "../../../types/HealthInstitution";
import { toast } from "react-toastify";
import { ServiceOrderRequest } from "../../../types/Requests/ConsultationRequest";
export function useSwitchPage(): (page: ApplicationPages)=> any{
  const dispatch = useDispatch()
  return useCallback((page) => {
      dispatch(setActivePage(page))
  }, [dispatch]);
}
export function useVerifyPayment(): (reference: any) => Promise<boolean> {
  return useCallback(async (reference) => {
    if (!reference) return false;
    try {
      const { data } = await axiosClient.post(
        `/payalt/verify-payment`,
        { reference }
      );

      return data.data.status === "success";
    } catch (error: any) {
      return false;
    }
  }, []);
}

export function useJoinAppointment(): (appointmentId: string, consultationId: string) => void {
  return useCallback(async (appointmentId, consultationId) => {
    try {
      await updateDoc(doc(db, consultationsCollection, consultationId, appointmentsCollections, appointmentId), {
        patientJoined: true,
        patientJoinedTime: new Date(),
      })
      return
    } catch (error) {
      console.log(' updating provider joined status', error)
    }
  }, [])
}
export function useUpdateAppointmentUserState(): (appointmentId: string, consultationId: string, label: any, status: any) => void {
  return useCallback(async (appointmentId, consultationId, label, status) => {
    try {
      await updateDoc(doc(db, consultationsCollection, consultationId, appointmentsCollections, appointmentId), {
        [label]: status,
      })
      return
    } catch (error) {
    }
  }, [])
}

export function useFetchDoctorById(): (doctorId: string) => Promise<any> {
  return useCallback(async (doctorId) => {
    try {
      const response = await axios.post(`${firebaseBaseUrl}/systemalt/doctor-metrics`, { doctorId })
      return response.data
    } catch (error) {
    }
  }, [])
}

export function useDoctorSpecialties(): () => void {
  const dispatch = useDispatch();
  return useCallback(async () => {
    try {
      const querySnapshot = await getDocs(collection(db, `practitioners`));
      const specialties: Specialty[] = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        specialties.push({ id: doc.id, ...doc.data() } as Specialty);
      });
      dispatch(setSpecialties(specialties));
    } catch (error) {
    }
  }, [dispatch]);
}

export function useUpdateCart(): (services: Service[]) => void{
  const dispatch = useDispatch()
  const { cart } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );
  return useCallback(
    (services) => {
      try {
        services.forEach((service)=>{
          const isChecked = cart.some((item) => service.id === item.id);
          if (isChecked) {
            cart?.filter((cartItem) => cartItem.id !== service.id)
              .length > 0
              ? dispatch(
                  setCart([...cart?.filter(
                    (cartItem) => cartItem.id !== service.id
                  )])
                )
              : dispatch(setCart([]));
          } else {
            dispatch(
              setCart([
                ...cart,
                    {
                      id: service.id,
                      name: service.title,
                      quantity: 1,
                      price: service.cost,
                      type: service.category,
                      image: service.image
                    },
              ],)
            );
          }
        })
       
      } catch (error) {
        
      }
    },
    [cart],
  )
  
}

export function useCreateServiceOrderItem(): (serviceOrder: ServiceOrderRequest, callback: any) => Promise<void> {    

  return useCallback(async (serviceOrder: any, callback: (arg0: any) => void) => {
   try {
     const { data } = await axiosClient.post(
       `/institutionalt/create-service-order`,
       serviceOrder
     );
     callback(data)
   } catch (error: any) {
     const errorData = error.response.data

     toast.error(`${errorData.message || 'Error Contact Support'}`)
     callback({
       status: 'Error',
       code: errorData.code??0,
       message: errorData.message || 'Error',
       data:errorData
     })
   }
 }, []);
}