// import { AppDispatch, AppState } from "..";
// import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useState, useCallback, useMemo } from 'react'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getAuth,
  verifyPasswordResetCode,
  applyActionCode,
  signInWithCustomToken,
} from 'firebase/auth'
import {
  doc,
  collection,
  updateDoc,
  query,
  where,
  getDocs,
  increment,
  arrayUnion,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import axios from 'axios'
import { CreatePatientRequest } from '../../../types/Requests/UserRequests'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, setUserProfile } from '.'
import { axiosClient } from '../../../config/axiosClient'
import { ApplicationPages, initializeUser } from '../applicationSlice'
import { SendOtpRequest, VerifyOtpRequest } from '../../../types/AuthRequest'
import { firebaseBaseUrl, usersCollection } from '../../../constants/firebase'
import { db } from '../../../config/firebase'
import { AppState } from '../../../state'
import { getTimestampDate } from '../../../functions'
import { PromoCode } from '../../../types'
import { RequestConsultationReq } from '../../../types/Requests/ConsultationRequest'
import { BookingSource } from '../../../types/Consultation'
import { getUserData, getUserProfileData } from '../../../functions/firebase'
import { useSwitchPage } from '../applicationSlice/hooks'

//AUTH
export function useUserAuth(): {
  loading: boolean,
  login: ({ email, password, phoneNumber }: { email?: string; password: string; phoneNumber?: string },  callback?: (val: any) => void) => void,
  createPatient: (
    { firstName, lastName, email, password, phoneNumber, referrer, quickSignIn }: CreatePatientRequest,
    callback?: (val: any) => void,
  ) => void,
  loginWithCredentials: ({ customToken }: { customToken: string }, callback?: (val: any) => void) => void,
} {
  const dispatch = useDispatch()
  const auth = getAuth()
  const [loading, setLoading] = useState(false)

  const router = useSwitchPage()

  const login = useCallback(
    async (
      { email, password, phoneNumber }: { email?: string; password: string; phoneNumber?: string },
      callback?: (val: any) => void,
    ) => {
      setLoading(true)
      if (email) {
        await signInWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // Signed in
            const userAccount = await getUserData(userCredential.user.uid)
            const userProfile = await getUserProfileData(userCredential.user.uid, userAccount.primary_member_id)

            setLoading(false)
            // if (callback) {
            //   callback(userProfile)
            // } else {
            callback && callback(userProfile)
            dispatch(setUser(userAccount))
            dispatch(setUserProfile(userProfile))
            // }
          })
          .catch((error) => {
            const errorCode = error.code
            let errorMessage = error.message
            if (errorCode) {
              switch (errorCode) {
                case 'auth/wrong-password':
                case 'auth/invalid-email':
                case 'auth/user-not-found':
                  {
                    errorMessage = 'invalid password or username'
                  }
                  break
              }
            }
            toast.error(errorMessage)
            setLoading(false)
          })
      } else if (phoneNumber) {
        if (parseInt(phoneNumber, 10).toString().length < 9) {
          toast.error('Phone number is invalid')
          setLoading(false)
        }

        try {
          const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/webalt/login-patient`, { phoneNumber, password })
          login({ email: data.email, password })
        } catch (error) {
          toast.error('Phone number is invalid')
          setLoading(false)
        }
      }
    },
    [],
  )
  const createPatient = useCallback(
    async (
      {
        referrer,
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        dob,
        gender,
        orgId,
        institutionId,
        quickSignIn,
      }: CreatePatientRequest,
      callback?: (val: any) => void,
    ) => {
      setLoading(true)
      try {
        const { data } = await axiosClient.post(`/webalt/create-patient`, {
          firstName,
          lastName,
          email,
          password,
          phoneNumber,
          dob,
          gender,
          referrer,
          ...(orgId && { orgId }),
          ...(institutionId && { institutionId }),
          ...(quickSignIn && { quickSignIn }),
          miscData:{
            source: BookingSource.BOOKING_PAGE
          }
        })
        if (data.customToken) {
          loginWithCredentials({ customToken: data.customToken }, () => {
            callback&& callback('success')
          })
        } else {
          login({ phoneNumber, password }, ()=>{
            callback&& callback('success')
          })
        }
      } catch (error: any) {
        setLoading(false)
        if (
          error?.response?.data?.code === 'auth/email-already-exists' ||
          error?.response?.data?.code === 'auth/phone-number-already-exists'
        ) {
          toast.error('Account Exists, Login to continue')
          router(ApplicationPages.HOME)
        } else {
          toast.error(error?.response?.data?.message || error?.message || '')
        }
      }
    },
    [],
  )

  const loginWithCredentials = useCallback(async ({ customToken }: { customToken: any }, callback?: (val: any) => void) => {
    setLoading(true)
    signInWithCustomToken(auth, customToken)
      .then(async (userCredential) => {
        // Signed in
        setLoading(false)
        const userAccount = await getUserData(userCredential.user.uid)

        const userProfile = await getUserProfileData(userCredential.user.uid, userAccount.primary_member_id)
        setLoading(false)
        dispatch(setUser(userAccount))
        dispatch(setUserProfile(userProfile))
        callback && callback('success')
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        // ...
      })
  }, [])
  return {loading, login, createPatient, loginWithCredentials}
}
export function useLogoutUser(): () => void {
  const dispatch = useDispatch()
  const auth = getAuth()
  return useCallback(async () => {
    auth.signOut()
    dispatch(initializeUser(null))
    dispatch(setUser(null))
  }, [])
}
export function useResetPassword(): (email: string, callback?: (val: boolean) => void) => void {
  const auth = getAuth()
  return useCallback(
    async (email, callback) => {
      await sendPasswordResetEmail(auth, email)
        .then((res) => {
          callback && callback(true)
          toast.success('Email sent successfully')
        })
        .catch((error) => {
          callback && callback(false)
          toast.error('Error sending email. Check email')
        })
    },
    [auth],
  )
}

//USER PROFILE


export function useOneTimePay(): (
  data: {
    amount: any;
    email: any;
    patientId: any;
    channels?: any
  },
  callback?: any
) => void {

  return useCallback(async (data, callback) => {
    try {

      const datum: {
        data: { data: { planId: any; authorization_url: any; reference: any } };
      } = await axiosClient.post(`/payalt/top-up`, {...data, callBackUrl: window.location.href});
      callback && callback(datum.data.data.reference);
      window.open(datum.data.data.authorization_url)
    } catch (error: any) {
    }
  }, []);
}
export function useSendOtp(): (data: SendOtpRequest, callback: (data: any) => void) => void {
  return useCallback(async (data, callback) => {
    try {
      const response = await axiosClient.post(`/webalt/send-otp`, data)
      // toast.success('OTP Succesfully sent to phone number')
      callback(response.data)
    } catch (error) {
      toast.error('Error sending OTP, Click on the help icon to get assistance')
      callback(null)
    }
  }, [])
}
export function useValidatePhoneNumber(): (
  data: SendOtpRequest,
  callback: (data: any) => void
) => void {

  const auth = getAuth();
  return useCallback(
    async (data, callback) => {
      try {
        const response = await axios.post(`/webalt/validate-phone`, data);
        callback(response.data);
      } catch (error) {
        callback(null);
      }
    },
    [auth]
  );
}
export function useVerifyOtp(): (data: VerifyOtpRequest, callback: (data: any) => void) => void {
  return useCallback(async (data, callback) => {
    try {

      const response = await axiosClient.post(`${firebaseBaseUrl}/webalt/verify-otp`, data)
      callback({
        status: 'success',
        ...response.data,
      })
    } catch (error: any) {
      if (error?.pinError === 'TTL_EXPIRED') {
        toast.error('OTP Expired, resend')
      } else {
        toast.error(`${error.response.data.message ?? 'Error verifying OTP'}`)
      }
      callback({
        status: 'error',
        ...error.response.data,
      })
    }
  }, [])
}
export function useVerifyEmail(): (
  data: {
    auth: any
    actionCode: any
    continueUrl: any
    lang: any
  },
  callback: (data: any) => void,
) => void {
  return useCallback(async (data, callback) => {
    const { auth, actionCode, continueUrl, lang } = data
    try {
      const response = await applyActionCode(auth, actionCode)
      // await updateUser({})
      toast.success('Email Verified')
      callback('success')
    } catch (error: any) {
      const errorCode = error.code
      let errorMessage = error.message
      if (errorCode) {
        switch (errorCode) {
          case 'auth/invalid-action-code':
            {
              errorMessage = 'Verification Failed; Retry'
            }
            break
        }
      }
      callback('failed')
      toast.error(errorMessage)
    }
  }, [])
}
export function useApplyPromo(): (promoCode: string, callback: any) => void {
  const { User } = useSelector<AppState, AppState["auth"]>(
    (state) => state.auth
  );
  const auth = getAuth();
  return useCallback(
    async (promoCode, callback) => {
      try {
        const q = query(
          collection(db, "promo_codes"),
          where("code", "==", promoCode.toUpperCase())
        );
        const data = await getDocs(q);
        if (data.size === 0) {
          callback({
            status: "FAILED",
            message: "Invalid Promo Code",
          });
        }
        const docd = data.docs[0];
        const promo = data.docs[0].data() as PromoCode;

        if (getTimestampDate(promo.expiry) < new Date()) {
          callback({
            status: "FAILED",
            message: "Promo Expired",
          });
          return;
        }
        if (!promo.active) {
          callback({
            status: "FAILED",
            message: "Promo inactive",
          });
          return;
        }

        if (promo.accounts.includes(auth?.currentUser?.uid ?? '')) {
          callback({
            status: "FAILED",
            message: "Promo already used",
          });
          return;
        }
        if (promo.max && promo.accounts.length >= promo.max) {
          callback({
            status: "FAILED",
            message: "Promo limit reached",
          });
          return;
        }
        // if(!(User && User.completed_assessment)){
        //   callback({
        //     status:'FAILED',
        //     message:'Complete health assessment to apply this promo'
        //   })
        //   return
        // }
        if (promo.type === "PERK") {
          await updateDoc(doc(db, usersCollection, auth?.currentUser?.uid ?? ''), {
            //TO BE REWORKED
            [`perks.${promo.perk.perk_type}.${promo.perk.perk_sub_type}`]:
              increment(promo.perk.perk_amount),
          });
          await updateDoc(docd.ref, {
            accounts: arrayUnion(auth?.currentUser?.uid),
          });
          callback({
            status: "SUCCESS",
            message: `Promo applied, you have ${promo.description}`,
          });
        } else {
          callback({
            status: "SUCCESS",
            message: `Promo applied, you have ${promo.description}`,
            promoCodeData: { ...promo, id: docd.id },
          });
        }
      } catch (error) {
        console.log("error applying promo code", error);
      }
    },
    [User]
  );
}

export function useRequestConsultation(): (
  datum: RequestConsultationReq,
  callback: any
) => void {

  return useCallback(async (datum, callback) => {
    try {
      const { data } = await axiosClient.post(
        `/webalt/request-consultation`,
        datum
      );
      callback({
        status: "Success",
        message: "Requested Succesfully",
        data: { cid: data.data.cid, aid: data.data.aid },
      });
    } catch (error: any) {
      console.error("error in requesting consultation", error.response.data);
      const errorData = error.response.data;
      toast.error(`${errorData.message || "Error Contact Support"}`);
      callback({
        status: "Error",
        code: errorData.code ?? 0,
        message: errorData.message || "Error",
        data: errorData,
      });
    }
  }, []);
}