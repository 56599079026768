import React, { useEffect, useState } from "react";
import PopupLayout from "./PopupLayout";
import { RotatingLines } from "react-loader-spinner";
import { DDGColors } from "./constants/colors";
import { Views } from "./views";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationPages, setActiveFacility, setActivePage, setInstitutionDoctors, setInstitutionLocations, setInstitutionServices } from "./state/features/applicationSlice";
import { getfacilityById, getfacilityBySlug, getInstitutionDoctors, getInstitutionLocations, getInstitutionServices } from "./functions/firebase";
import { AppState } from "./state";
import { useDoctorSpecialties, useSwitchPage } from "./state/features/applicationSlice/hooks";
import Image from "./components/Image";

interface MainProps {
  isPopUp: string;
  apiData: string;
}

const Main: React.FC<MainProps> = ({ isPopUp, apiData }) => {
  const [apiState, setAPIState] = useState('PENDING');

  const params = new URLSearchParams(window.location.search);
  const apiParam = params.get("slug");
  const popUpParam = params.get("is-popup");
  const apiKey = apiParam ?? apiData ?? "";
  const popUpValue =
    isPopUp ? Boolean(isPopUp) : popUpParam === 'true'
  const getLayout = popUpValue
    ? (children: any) => <PopupLayout>{children}</PopupLayout>
    : (children: any) => <>{children}</>;

  if (typeof window !== "undefined") {
    localStorage.setItem("apikey", apiKey);
  }
  const { activePage,cart } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );
  const dispatch = useDispatch();
  const getSpecialties = useDoctorSpecialties()
  useEffect(() => {
    if (apiKey) {
      const getActiveDoctor = async () => {
      

        const facility = await getfacilityBySlug(apiKey);
        
        !activePage && dispatch(setActivePage(ApplicationPages.HOME))
        if (facility) {
          const locations = await getInstitutionLocations(facility.id);
          const services = await getInstitutionServices(facility.id);
          const providers = await getInstitutionDoctors(facility.id);
          dispatch(setInstitutionLocations(locations))
          setAPIState("FETCHED");
          dispatch(setActiveFacility(facility));
          getSpecialties()
         
          dispatch(setInstitutionServices(services));
          dispatch(setInstitutionDoctors(providers));
        } else {
          setAPIState("INVALID");
        }
      };
      getActiveDoctor();
    }else{
      setAPIState("INVALID");
    }
  }, [dispatch, apiKey]);
  return (
    <>
      {getLayout(
        <>
          {apiState === 'PENDING' ? (
            <div style={{minHeight : 500}} className="relative flex items-center justify-center flex-col flex-grow min-w-[100vw] md:min-w-full w-full h-full max-w-lg mx-auto overflow-y-hidden min-h-[300px] bg-white">
              <RotatingLines width="20" strokeColor={DDGColors.gainsboro} />
            </div>
          ) : apiState === 'FETCHED' ? (
              <Views
              isPopUp={popUpValue}
              />
          ) : (
            <div style={{minHeight : 500}} className="relative flex items-center justify-center flex-col flex-grow min-w-[100vw] md:min-w-full w-full h-full max-w-lg mx-auto overflow-y-hidden min-h-[500px]">
              <Image useBase src="/icons/lost.svg" alt="" className="w-40 h-40"/>
              <div className="w-4/5 mx-auto text-center">
                <p className="font-bold text-gray900">Oops Seems You Got Lost</p>
                <p className="text-xs text-gray-400">You can contact your facility to give you the right booking link or call 0505929685</p>
              </div>
            </div>
          )}
        </>
      )}
       
    </>
  );
};
export default Main;
