import { Timestamp } from "firebase/firestore";
import { AvailabilityIntervals, DaySchedule, Doctor, MatchedDoctor } from "../types/User";
import _ from 'lodash'
import Address from "../types/Address";

export const addHours = (date: Date, hours: number) => {
  date.setMinutes(date.getHours() + hours);
  return date;
};
export const addMinutes = (date: Date, minutes: number) => {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};
export const minusMinutes = (date: Date, minutes: number) => {
  date.setMinutes(date.getMinutes() - minutes);
  return date;
};
export const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};
export const getTimestampDate = (time: Timestamp) => {
  if (time) {
    const newTime = new Timestamp(time.seconds, time.nanoseconds);

    if (
      typeof newTime === "object" &&
      newTime !== null &&
      "toDate" in newTime
    ) {
      return newTime.toDate();
    } else {
      return new Date();
    }
  }else{
    return new Date();
  }
};
export const getInitials = (name: string) => {
  if (name) {
    const Name = name && name.replace(/  +/g, " ");
    const userName = Name[0].match(/^[A-Z]/)
      ? Name.split(" ").length > 1
        ? Name.split(" ")[0][0] + Name.split(" ")[1][0]
        : Name.split(" ")[0][0]
      : "";
    return userName;
  }
  return "";
};
export const getDayInString = (day: number) =>{
  const desiredWeekdayIndex = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ][day]
return desiredWeekdayIndex
}
export function getNextWeekday(desiredWeekday: string) {
  const currentDay = new Date().getDay();
;
const desiredWeekdayIndex = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
].indexOf(desiredWeekday?.toLowerCase() ?? "sunday")
  let daysAhead = (desiredWeekdayIndex - currentDay + 7) % 7;
  // if (daysAhead === 0) {
  //   daysAhead = 7 // If it's the same weekday, move to the next week
  // }

  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() + daysAhead);
  return nextDate;
}
export const convertIntervalToDate = (
  interval: AvailabilityIntervals,
  type: "from" | "to"
) => {
  if (!interval) return;
  return new Date(
    getNextWeekday(interval.day).setHours(
      Number(interval[type].split(":")[0]),
      Number(interval[type].split(":")[1].split(" ")[0]),
      0,
      0
    )
  );
};
export function generateIntervals(
  interval: any,
  visitType: "VIRTUAL" | "IN-PERSON" | "CONCIERGE"
) {
  if (!interval) return;
  const startTime = convertIntervalToDate(interval, "from") ?? new Date();
  const endTime = convertIntervalToDate(interval, "to") ?? new Date();
  const intervalCount =
    visitType === "IN-PERSON" ? 40 : visitType === "CONCIERGE" ? 240 : 20;

  const intervals = [];
  const currentTime = new Date(startTime);

  while (currentTime <= endTime) {
    const minutes = currentTime.getMinutes();
    const roundedMinutes = Math.ceil(minutes / intervalCount) * intervalCount; // Round up to the nearest intervalCount minutes
    currentTime.setMinutes(roundedMinutes);
    intervals.push(
      currentTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    );
    currentTime.setTime(currentTime.getTime() + intervalCount * 60000); // Add intervalCount minutes to the current time
  }
  return intervals;
}
export const getAvailableIntervals = (availableSchedule: DaySchedule[], durationMinutes: number = 20, date: Date): string[] => {
  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const targetDate = date || new Date();
  const targetDay = daysOfWeek[targetDate.getDay()];
  const daySchedule = availableSchedule.find(schedule => schedule.day === targetDay);
  
  if (!daySchedule) {
    return [];
  }
  
  const startTimes: string[] = [];
  daySchedule.intervals.forEach(interval => {
    let [fromHour, fromMinute] = interval.from.split(':').map(Number);
    const [toHour, toMinute] = interval.to.split(':').map(Number);
    
    const toTimeInMinutes = toHour * 60 + toMinute;
    while (fromHour * 60 + fromMinute < toTimeInMinutes) {
      const newStartTime = `${fromHour.toString().padStart(2, '0')}:${fromMinute.toString().padStart(2, '0')}`
      startTimes.push(newStartTime);
      fromMinute += durationMinutes;
      fromHour += Math.floor(fromMinute / 60);
      fromMinute %= 60;
      
      if (fromHour * 60 + fromMinute > toTimeInMinutes) {
        break;
      }
    }
  });
  
  return startTimes;
};
export const getNextPossibleInterval = (
  availableSchedule: DaySchedule[],
  durationMinutes: number = 15
): AvailabilityIntervals | null => {
  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  let currentDate = new Date();
  const currentTime = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;

  for (let i = 0; i < 7; i++) {
    const availableTimes = getAvailableIntervals(availableSchedule, durationMinutes, currentDate);
    const currentDay = daysOfWeek[currentDate.getDay()];

    for (const time of availableTimes) {
      if (time >= currentTime || currentDate.getDate() > new Date().getDate()) {
        const [startHour, startMinute] = time.split(':').map(Number);
        const endTime = new Date(currentDate);
        endTime.setHours(startHour, startMinute + durationMinutes, 0, 0);
        
        return {
          from: time,
          to: `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}`,
          day: currentDay
        };
      }
    }

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0, 0);
  }

  return null;
};
export const getNextInterval = (
  doctor: Doctor,
  visitType: "VIRTUAL" | "IN-PERSON" | "CONCIERGE"
) => {
  const currentDateTime = new Date();
  const currentDay = currentDateTime
    .toLocaleDateString("en-US", { weekday: "long" })
    .toLowerCase()
    .split(",")[0];
  const currentTime = currentDateTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const availableSchedule =
    doctor.enabledServices[visitType].enabled &&
    _.clone(doctor.enabledServices[visitType].availability).sort(
      (a: { day: string; }, b: { day: string; }) =>
        getNextWeekday(a.day).getTime() - getNextWeekday(b.day).getTime()
    );
  if (!availableSchedule) {
    return;
  }
  const daysOfWeek = availableSchedule.map((schedule: { day: any; }) => schedule.day);
  // const sortedDays = sortDaysFromCurrentDay(currentDay)
  for (const day of daysOfWeek) {
    let availableSlot: MatchedDoctor;
    const availableDaySlot = availableSchedule.find(
      (slot: { day: any; intervals: string | any[]; }) => slot.day == day && slot.intervals.length > 0
    );
    if (availableDaySlot) {
      const intervals = availableDaySlot.intervals;
      if (currentDay !== day) {
        //get the first interval of the next available day
        availableSlot = {
          ...doctor,
          isAvailable: false,
          nextInterval: { ...intervals[0], day },
        };

        return availableSlot;
      } else {
        for (const interval of intervals) {
          if (day === currentDay && currentTime > interval.to) {
            continue; // Skip intervals that have already passed for the current day
          }
          if (currentTime >= interval.from && currentTime <= interval.to) {
            availableSlot = {
              ...doctor,
              isAvailable: day === currentDay,
              nextInterval: { ...interval, day },
            };
            // Current time falls within an available interval of the current day
            return availableSlot;
          } else if (currentTime <= interval.from) {
            // Current time falls behind an available interval of the current day

            availableSlot = {
              ...doctor,
              isAvailable: false,
              nextInterval: { ...interval, day },
            };
            return availableSlot;
          }
        }
      }
    }
  }
};
export function convertDateRanges(doctor: Doctor) {
    const timeRanges = Object.values(doctor.unAvailableDateRange || {}).map((range) => {
      return {
        startTime: getTimestampDate(range.startTime)?.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
        endTime: getTimestampDate(range.endTime)?.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
        day: getTimestampDate(range.startTime)?.toLocaleDateString('en-US', { weekday: 'long' })
          .toLowerCase()
          .split(',')[0],
      }
    })
    return { ...doctor, unavailableTimeRanges: timeRanges }
  }
export const filteredIntervals = (activeDay: Date, activeDoctor: MatchedDoctor, interval: any) => {
    const currentDateTime = new Date()
    const currentDay = currentDateTime.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase().split(',')[0]
    const currentTime = addMinutes(currentDateTime, 10).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      
    }) // 10 minutes ahead to offset wait time
    if ((activeDay.getDate() === new Date().getDate()) && currentTime > interval) {
      return
    } else if (
      convertDateRanges({ ...activeDoctor }).unavailableTimeRanges.some((range) => {
        if(range.startTime && range.endTime){
            return range.day === getDayInString(activeDay.getDay()) && interval >= range.startTime && interval < range.endTime
        } /// CHECK THIS AGAIN
      })
    ) {
      return
    }
    return interval
  }

export const shouldBeDefined = (
    value: any | undefined,
    valueName: string = "value"
  ) => {
    if (value === undefined) {
      throw new Error(`${valueName} is undefined`)
    }
  
    return value
  }
  export function formatNumberScale(number: any, currency = false) {
    if (isNaN(number) || number === "" || number === undefined) {
      return currency ? "₵0.00" : "0";
    }
    const num = parseFloat(number);
    const wholeNumberLength = String(Math.floor(num)).length;
  
    // if (wholeNumberLength >= 13)
    //   return (currency ? "₵" : "") + (num / Math.pow(10, 12)).toFixed(1) + "T";
    // if (wholeNumberLength >= 10)
    //   return (currency ? "₵" : "") + (num / Math.pow(10, 9)).toFixed(1) + "B";
    // if (wholeNumberLength >= 7)
    //   return (currency ? "₵" : "") + (num / Math.pow(10, 6)).toFixed(1) + "M";
    // if (wholeNumberLength >= 4)
    //   return (currency ? "₵" : "") + (num / Math.pow(10, 3)).toFixed(1) + "K";
  
    if (num < 0.0001 && num > 0) {
      return currency ? "< $0.0001" : "< 0.0001";
    }
  
    return (currency ? "₵" : "") + num.toFixed(2);
  }
  export function formatAddress(address:Address, hideStreet?: boolean, hideCountry?: boolean) {
    if(!address) return ''
    const {street, city, country, zip, state} = address;
    return`${!hideStreet ? `${street}, ` :''}${city}, ${state} ${!hideCountry ? `, ${country}, ` :''} ${zip}`;
  }

  export const convertDateToString = (date: Date, showTime?: boolean) =>{
    return `${new Date(date ?? 0).toDateString().split(' ').slice(0, 3).toString().replaceAll(',',' ')} ${showTime ? `${date.toLocaleTimeString().split(':').slice(0,2).toString().replaceAll(',',':')} `:''}`
  }