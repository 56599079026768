import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import { Loader } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { useFetchDoctorById } from "../../state/features/applicationSlice/hooks";
import { AppState } from "../../state";
import DoctorsPage from "../../components/Doctors/DoctorsPage";
import { DDGColors } from "../../constants/colors";
import { setActiveDoctor } from "../../state/features/applicationSlice";
function DoctorIntro() {
  const { activeDoctor } = useSelector<
    AppState,
    AppState["application"]
  >((state) => state.application);
  let params = new URLSearchParams(document.location.search);
  const queryId =  params.get("id")

  const fetchDoctorById = useFetchDoctorById();
  const dispatch = useDispatch()
  useEffect(() => {
    if (queryId) queryDataFunc();
  }, [queryId]);
  const queryDataFunc = async () => {
    const doctorData = await fetchDoctorById(queryId ?? '');
    dispatch(setActiveDoctor(doctorData))
  };

  if (!activeDoctor) {
    return (
      <div style={{minHeight : 500}} className="relative w-[100vw] flex items-center justify-center flex-col flex-grow md:w-full h-full max-w-lg mx-auto overflow-y-hidden min-h-[300px]">
        <TailSpin width={20} height={20} color={DDGColors.primary} />
      </div>
    );
  }
  return (
      <DoctorsPage activeDoctor={activeDoctor.doctor} doctorReviews={activeDoctor.reviews} />
  );
}

export default DoctorIntro;
