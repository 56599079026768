// import { format } from "date-fns";
import { getAuth } from "firebase/auth";

import React, { useEffect, useMemo, useState } from "react";
import {
  Check,
  CheckCircle,
  Clipboard,
  Delete,
  File,
  Loader,
  Plus,
  Tag,
  Trash,
  Trash2,
  X,
} from "react-feather";
import { RotatingLines, TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MatchedDoctor } from "../../types/User";
import { AppState } from "../../state";
import {
  useApplyPromo,
  useOneTimePay,
  useRequestConsultation,
} from "../../state/features/authSlice/hooks";
import {
  useCreateServiceOrderItem,
  useSwitchPage,
  useUpdateCart,
  useVerifyPayment,
} from "../../state/features/applicationSlice/hooks";
import { PromoCode } from "../../types";
import { getInstitutionLocations, getUserData } from "../../functions/firebase";
import { setUser } from "../../state/features/authSlice";
import { BookingSource } from "../../types/Consultation";
import { ResponseStatus } from "../../types/Requests/Status";
import {
  ApplicationPages,
  setActiveBooking,
  setCart,
} from "../../state/features/applicationSlice";
import errorcodes from "../../constants/errorcodes";
import { DDGColors } from "../../constants/colors";
import Image from "../Image";
import { Booking, Location, Service } from "../../types/HealthInstitution";
import {
  addMinutes,
  convertDateToString,
  formatAddress,
  formatNumberScale,
} from "../../functions";
import SelectInput from "../SelectInput";

const ServiceBookingReview: React.FC = () => {
  const { User, Account, PaymentMethods } = useSelector<
    AppState,
    AppState["auth"]
  >((state) => state.auth);
  const { activeBooking, cart, activeFacility, institutionLocations } =
    useSelector<AppState, AppState["application"]>(
      (state) => state.application
    );
  const urlParams = new URLSearchParams(window.location.search);
  const trxref = urlParams.get("trxref");
  const [selectedMethod, setSelectedMethod] = useState("WALLET");
  const [deliveryMethod, setDeliveryMethod] = useState<"PICKUP" | "DELIVERY">(
    "DELIVERY"
  );
  const router = useSwitchPage();
  const pay = useOneTimePay();
  const verifyPayment = useVerifyPayment();
  const orderFunc = useCreateServiceOrderItem();
  const [loading, setLoading] = useState(false);
  const [paying, setPaying] = useState(false);
  const [payReference, setPayReference] = useState("");
  const [order, setOrder] = useState<Booking>();
  const [locationId, setLocationId] = useState<string>("");
  const [bookingDate, setBookingDate] = useState<string>(new Date().toString());
  const [bookingTime, setBookingTime] = useState<string>(new Date().toString());

  const updateCart = useUpdateCart();

  const continueFunc = async () => {
    setLoading(true);
    // if (params?.editable) {
    orderFunc(
      {
        items: cart,
        patientMemberId: User?.memberID ?? "",
        patientId: User?.accountId ?? "",
        paymentMethod: activeFacility?.paymentConfig.accepts_payment_on_delivery
          ? "CASH"
          : (selectedMethod as
              | "WALLET"
              | "CASH"
              | "CARD"
              | "MOMO"
              | "mobile_money"),
        ignorePayment:
          activeFacility?.paymentConfig.accepts_payment_on_delivery ?? false,
        fees: {
          serviceFee: activeFacility?.paymentConfig.accepts_payment_on_delivery
            ? 0
            : 15,
          deliveryFee: 0,
        },
        deliveryMethod: "ON-SITE",
        locationId,
        startTime: new Date(bookingTime ?? 0),
        facilityId: activeFacility?.id ?? "",
      },
      async ({ status, code, message, data }: any) => {
        if (status === ResponseStatus.SUCCESS) {
          setLoading(false);
          urlParams.set("location", locationId);
          urlParams.set("type", "service");
          urlParams.set("time", bookingDateTime.toString() ?? "");
          window.history.pushState(null, "", "?" + urlParams.toString());
          toast.success("Service successfully booked");
          router(ApplicationPages.BOOKING_SERVICE_SUCCESS);
        }
        if (code === errorcodes.insuficientCredits) {
          setPaying(true);
          // call pay func
          pay(
            {
              amount: (100 * data.data.amount + 1).toFixed(2),
              email: User?.email,
              patientId: User?.accountId,
            },
            (ref: string) => {
              setPayReference(ref);
            }
          );
        } else if (status === ResponseStatus.ERROR) {
          setLoading(false);
          toast.error("Error placing order");
        }
      }
    );
    // } else {
    //   payOrder(order.id, async ({ status, code, message, data }) => {
    //     console.log("requestConsultation data", data);
    //     if (status === ResponseStatus.SUCCESS) {
    //       setLoading(false);
    //       await track("placed_sheba_order");
    //       toast("Order succesfully placed", "SUCCESS");
    //       navigation.goBack();
    //     }
    //     if (code === errorcodes.insuficientCredits) {
    //       await track("insufficient_balance");

    //       setPaying(true);
    //       // call pay func
    //       pay(
    //         {
    //           amount: 100 * data.data.amount,
    //           email: auth.currentUser.email,
    //           patientId: auth.currentUser.uid,
    //         },
    //         (ref) => setPayReference(ref)
    //       );
    //     } else if (status === ResponseStatus.ERROR) {
    //       setLoading(false);
    //       toast("Error placing order", "ERROR");
    //     }
    //   });
    // }
  };
  const discountAmount = useMemo(() => {
    return [...(order?.items ? order.items : [])].reduce(
      (acc, val) =>
        acc +
        (activeFacility?.paymentConfig.discount
          ? val.price * activeFacility?.paymentConfig.discount
          : 0) *
          val.quantity,
      0
    );
  }, [order]);
  const orderAmount = useMemo(() => {
    return [...(order?.items ? order.items : [])].reduce(
      (acc, val) =>
        acc +
        (activeFacility?.paymentConfig.discount
          ? val.price - val.price * activeFacility?.paymentConfig.discount
          : val.price) *
          val.quantity,
      0
    );
  }, [order]);
  const bookingDateTime = useMemo(() => {
    return new Date(bookingDate).setHours(
      Number(bookingTime.split(":")[0]),
      Number(bookingTime.split(":")[1])
    );
  }, [bookingDate, bookingTime]);
  useEffect(() => {
    const bookingOrder: Booking = {
      deliveryDetail: {
        method: "ON-SITE",
      },
      orderAmount: 0,
      status: "PENDING",
      paymentStatus: "PAID",
      bookingId: "",
      createdAt: new Date(),
      updatedAt: new Date(),
      facility_details: {
        name: "",
        id: activeFacility?.id ?? "",
        email: "",
        logo_url: "",
      },
      location_details: {
        name: "",
        id: "",
        email: "",
        address: {
          city: "",
          state: "",
          zip: "",
          lat: 0,
          lng: 0,
          hash: "",
        },
      },
      startTime: addMinutes(new Date(), 60) as any,
      paymentMethod: "CASH",
      items: cart,
      misc: "",
      note: "",
      patientDetails: {
        name: "",
        id: "",
        email: "",
        memberId: "",
      },
      institutionId: "",
    };
    setOrder(bookingOrder);
  }, []);
  const verifyTransaction = async (txref: string) => {
    const paid = await verifyPayment(txref);
    if (paid) {
      setSelectedMethod("WALLET");
    } else {
      setLoading(false);
      toast.error("Unable to verify payment");
    }
    urlParams.delete("trxref");
    setPaying(false);
  };
  useEffect(() => {
    if (trxref) {
      verifyTransaction(trxref as string);
    }
  }, [trxref]);
  if (!Account) {
    return <></>;
  }
  return (
    <div className="flex flex-col flex-1 space-y-4 overflow-y-scroll scroll">
      <div className="p-2 ">
        <p className="text-lg font-bold text-gray900">Confirm Your Booking</p>
        <p className="text-xs text-gray500">
          You&apos;ll receive a video call link on your phone for the
          consultation after booking.
        </p>
      </div>
      <div className="px-2">
        <p className="text-[10px] font-semibold text-gray700">Your items</p>
        <div className="">
          {cart.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex flex-row items-center  py-4 ${
                  index === 0
                    ? ""
                    : "border-[#D6D6D6] border-opacity-50 border-t"
                }`}
              >
                <div className="">
                  <Image
                    className="object-cover w-[54px] h-[54px] rounded-lg"
                    useBase={false}
                    src={
                      Boolean(item.image)
                        ? item.image!
                        : activeFacility?.details.coverImage ??
                          "/img/surgery.png"
                    }
                    alt=""
                  />
                </div>
                <div className="flex flex-col flex-1 px-3 space-y-1">
                  <p className="text-sm font-bold">{item.name}</p>
                  <p className="text-xs font-semibold">
                    ₵{formatNumberScale(Number(item.price) + 15)}
                  </p>
                </div>
                <div>
                  {cart.length > 1 && (
                    <button
                      onClick={() => {
                        updateCart([item as any]);
                      }}
                      className="p-3 rounded-full bg-lightBlood"
                    >
                      <Trash2 color={DDGColors.blood} size={12} />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="px-2 ">
        <div className="border-[#D6D6D6] border-opacity-50 border-b pb-4">
          <p className="text-[10px] font-semibold text-gray700">
            Select a Location
          </p>
          <p className="text-[#667085] text-xs mb-2">
            Select a convenient location from the options provided for your lab
            test.
          </p>
          <select
            onChange={(val) => {
              setLocationId(val.target.value);
            }}
            className="border-[1px] mt-2 rounded-[8px] border-[#D0D5DD]  outline-none md:text-sm text-xs font-[400] w-full bg-transparent flex items-center p-3 text-[#98A2B3] cursor-pointer"
          >
            <option value="none" selected disabled hidden>
              Select an Option
            </option>
            {[...institutionLocations].map((item, index) => {
              const isChecked = item.id === locationId;
              return (
                <option
                  selected={locationId === item.id}
                  value={item.id}
                  className="flex-row items-center justify-between my-2"
                >
                  <div className="flex-1">
                    <p className="text-xs font-bold text-dark">
                      {item.locationName}
                    </p>
                    <p className="text-xs font-bold text-gray-500">
                      {formatAddress(item.address)}
                    </p>
                  </div>
                  <div
                    className={`items-center justify-center w-6 h-6 rounded-full ${
                      isChecked
                        ? "bg-primary"
                        : "border-2 border-gray-300 cursor-pointer"
                    }`}
                  >
                    {isChecked ? (
                      <CheckCircle color="rgb(237,251,248)" size={16} />
                    ) : (
                      <></>
                    )}
                  </div>
                </option>
              );
            })}
          </select>
        </div>
        <div className="border-[#D6D6D6] border-opacity-50 border-b py-4">
          <p className="text-[10px] font-semibold text-gray700">
            Select a Time
          </p>
          <p className="text-[#667085] text-xs mb-2">
            Select a date and time within our opening hours for your lab test.
          </p>
          <div className="flex flex-row space-x-3">
            <input
              value={bookingDate}
              onChange={(val) => {
                setBookingDate(val.target.value);
              }}
              type="date"
              placeholder="Select a date"
              className="border-[1px] rounded-[8px] border-[#D0D5DD]  outline-none md:text-sm text-xs font-[400] w-full bg-transparent flex items-center p-2 text-[#98A2B3] cursor-pointer"
            />
            <input
              value={bookingTime}
              onChange={(val) => {
                setBookingTime(val.target.value);
              }}
              type="time"
              placeholder="Select a time"
              className="border-[1px] rounded-[8px] border-[#D0D5DD]  outline-none md:text-sm text-xs font-[400] w-full bg-transparent flex items-center p-2 text-[#98A2B3] cursor-pointer"
            />
          </div>
        </div>
      </div>
      {/* <div className="px-2 mt-2 space-y-4">
        {Boolean(discountAmount) && (
          <div className="flex flex-row items-center justify-between">
            <p className="text-xs">Discount</p>
            <p className="text-xs">
              - {formatNumberScale(discountAmount, true)}
            </p>
          </div>
        )}
        <div className="flex flex-row items-center justify-between">
          <p className="text-xs font-bold">Subtotal</p>
          <p className="text-xs font-bold">
            {formatNumberScale(orderAmount, true)}
          </p>
        </div>
        <div className="flex flex-row items-center justify-between">
          <p className="text-xs">Service fee</p>
          <p className="text-xs">
            {activeFacility?.paymentConfig.accepts_payment_on_delivery
              ? 0
              : formatNumberScale(15, true)}
          </p>
        </div>
        <div className="flex flex-row items-center justify-between py-4 border-t border-gray-300 ">
          <p className="text-xs font-bold">Total</p>
          <p className="text-xs font-bold">
            {formatNumberScale(
              orderAmount +
                (activeFacility?.paymentConfig.accepts_payment_on_delivery
                  ? 0
                  : 15),
              true
            )}
          </p>
        </div>
      </div> */}
      <div className="flex-1 px-2 ">
        <p className="text-[10px] font-semibold text-gray700">
          Select your payment method
        </p>
        <div className="relative flex flex-col mt-4 space-y-4">
          {Account.balance.value >= (discountAmount || orderAmount) && (
            <button
              onClick={() => {
                setSelectedMethod("WALLET");
              }}
              className="flex flex-row items-center w-full space-x-3 "
            >
              <div
                className={`self-start flex items-center justify-center w-5 h-5 rounded-full ${
                  selectedMethod === "WALLET"
                    ? "bg-primary"
                    : "border-2 border-gray-300 cursor-pointer"
                }`}
              >
                {selectedMethod === "WALLET" ? (
                  <Check size={12} color={DDGColors.primaryLight} />
                ) : (
                  <></>
                )}
              </div>
              <div className="flex-row  flex items-center justify-between flex-1  border-opacity-40 border-b pb-3 border-[#D6D6D6]">
                <div className="flex flex-col items-start flex-1 ">
                  <p className="font-semibold text-gray900 text-xs mb-0.5">
                    DDG Balance
                  </p>
                  <p className="text-[11px] text-gray500">
                    ₵
                    {(
                      Account.balance.value + (Account.balance?.org_value ?? 0)
                    ).toFixed(2)}
                  </p>
                </div>
                <div className="items-center flex justify-center rounded-full p-1.5 bg-primaryLight">
                  <Image
                    useBase
                    alt="wallet"
                    src={"/img/empty-wallet.svg"}
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </button>
          )}
          <button
            onClick={() => {
              setSelectedMethod("mobile_money");
            }}
            className="flex flex-row items-center w-full space-x-3 "
          >
            <div
              className={`self-start flex items-center justify-center w-5 h-5 rounded-full ${
                selectedMethod === "mobile_money"
                  ? "bg-primary"
                  : "border-2 border-gray-300 cursor-pointer"
              }`}
            >
              {selectedMethod === "mobile_money" ? (
                <Check size={12} color={DDGColors.primaryLight} />
              ) : (
                <></>
              )}
            </div>
            <div className="flex-row  flex items-center justify-between flex-1  border-opacity-40 border-b pb-3 border-[#D6D6D6]">
              <div className="flex flex-col items-start flex-1 ">
                <p className="text-xs font-semibold text-gray900 ">
                  Mobile Money
                </p>
              </div>
              <div className="">
                <Image
                  useBase
                  width={100}
                  height={20}
                  alt="wallet"
                  src={"/img/momo.svg"}
                  className="object-contain"
                />
              </div>
            </div>
          </button>
          <button
            onClick={() => {
              setSelectedMethod("CARD");
            }}
            className="flex flex-row items-center w-full space-x-3 "
          >
            <div
              className={`self-start flex items-center justify-center w-5 h-5 rounded-full ${
                selectedMethod === "CARD"
                  ? "bg-primary"
                  : "border-2 border-gray-300 cursor-pointer"
              }`}
            >
              {selectedMethod === "CARD" ? (
                <Check size={12} color={DDGColors.primaryLight} />
              ) : (
                <></>
              )}
            </div>
            <div className="flex-row  flex items-center justify-between flex-1  border-opacity-40 border-b pb-3 border-[#D6D6D6]">
              <div className="flex flex-col items-start flex-1 ">
                <p className="text-xs font-semibold text-gray900 ">Bank Card</p>
              </div>
              <div className="">
                <Image
                  useBase
                  width={50}
                  height={20}
                  alt="wallet"
                  src={"/img/cards.png"}
                  className="object-contain"
                />
              </div>
            </div>
          </button>
          {[
            ...[...PaymentMethods].map((method, index) => {
              return {
                title: `Card ending in ${method.last4}`,
                card_type: method.card_type,
                id: method.id,
                icon: (
                  <Image
                    useBase
                    src={
                      method.card_type.trim() === "visa"
                        ? "/img/visa-logo.svg"
                        : "/img/visa-logo.svg"
                    }
                    alt="card"
                    width={100}
                    height={20}
                    className="object-contain"
                  />
                ),
              };
            }),
          ].map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setSelectedMethod(item.id);
                }}
                className="flex flex-row items-center w-full space-x-3 "
              >
                <div
                  className={`self-start flex items-center justify-center w-5 h-5 rounded-full ${
                    selectedMethod === item.id
                      ? "bg-primary"
                      : "border-2 border-gray-300 cursor-pointer"
                  }`}
                >
                  {selectedMethod === item.id ? (
                    <Check size={12} color={DDGColors.primaryLight} />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex-row  flex items-center justify-between flex-1  border-opacity-40 border-b pb-3 border-[#D6D6D6]">
                  <div className="flex flex-col items-start flex-1 ">
                    <p className="text-xs font-semibold text-gray900 ">
                      {item.title}
                    </p>
                  </div>
                  <div className="">{item.icon}</div>
                </div>
              </button>
            );
          })}
          {/* <button
    onClick={() => {}}
    className="flex flex-row items-center w-full space-x-3 "
  >
    <div className="items-center flex justify-center rounded-full p-1.5 bg-primaryLight">
      <Plus size={12} color={DDGColors.primary} />
    </div>
    <div className="flex flex-row items-center justify-between flex-1 ">
      <div className="flex flex-col items-start flex-1 ">
        <p className="text-xs font-semibold text-gray900 ">
          Add new card
        </p>
      </div>
    </div>
  </button> */}
        </div>
      </div>
      <div
        className={`
px-2 pt-2 
w-full  text-dark  border-t-2 border-gray-50`}
      >
        <div className="flex flex-row items-end justify-between">
          <div className="w-1/3">
            <p className="text-sm font-bold text-primary">
              ₵
              {formatNumberScale(
                Number((discountAmount || orderAmount)?.toFixed(2)) + 15
              )}
            </p>
            <p className="text-xs font-bold ">
              {convertDateToString(new Date(bookingDateTime ?? 0), true)}
            </p>
            <p className="text-[10px] text-gray-400 font-light">
              Free cancellation*
            </p>
          </div>
          <div className="w-1/2 pl-3">
            <button
              className={`flex items-center justify-center w-full py-3 rounded-md  ${
                locationId && bookingDateTime ? "bg-primary" : "bg-gray-300"
              }`}
              disabled={loading || !locationId || !bookingDateTime}
              onClick={() => {
                continueFunc();
              }}
            >
              {loading ? (
                <RotatingLines width="20" strokeColor={DDGColors.gainsboro} />
              ) : (
                <p className="text-xs font-semibold text-white">Continue</p>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBookingReview;
