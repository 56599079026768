import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  Doctor,
  MatchedDoctor,
  ServiceItem,
  Specialty,
} from "../../../types/User";
import { HealthInstitution, Location, Service } from "../../../types/HealthInstitution";
import { Appointment, Consultation, Review } from "../../../types/Consultation";
export enum ApplicationModal {
  MISC,
  APPOINTMENT_SCHEDULER,
  APPOINTMENT_REQUEST,
  REVIEW,
  PROFILE_EDIT,
  FORGOT_PASSWORD,
  SUBSCRIPTION,
  ADD_DOSE,
  PROFILE_CHANGE,
  NEW_PROFILE,
}

export enum ApplicationPages {
 HOME,
 SPEAK_TO_DOCTOR,
 DOCTOR,
 START,
 BOOKING_SERVICE_START,
 SUCCESS,
 BOOKING_SERVICE_SUCCESS
}
interface SnackType {
  content: string;
  type: "SUCCESS" | "ERROR" | "DEFAULT" | "INFO";
  duration: number;
}
export interface ApplicationState {
  snackContent: SnackType | null;
  deviceId: string | null;
  activeBooking: {
    visitType: {
      name: string;
      value: "VIRTUAL" | "IN-PERSON" | "CONCIERGE";
    },
    complaints: string,
startTime: number | null,
  }
  activeDoctor: {
    appointments: Appointment[]
    consultations: Consultation[]
    reviews: Review[]
    doctor: MatchedDoctor
  } | null
  activeFacility: HealthInstitution | null
  activePage: ApplicationPages | null
  institutionServices: Service[]
  institutionDoctors: Doctor[]
  specialties: Specialty[];
  cart: Array<ServiceItem>;
  institutionLocations: Location[]
}

export const initialApplicationState: ApplicationState = {
  specialties: [],
  activeBooking: {
    visitType: {
      name: 'Video/Virtual visit',
      value: "VIRTUAL"
    },
    complaints: '',
    startTime: null,
  },
  activeDoctor: null,
  snackContent: null,
  deviceId: null,

  activeFacility:null,
  activePage: null,
  institutionServices: [],
  institutionDoctors: [],
  cart: [],
  institutionLocations: []
};

export const applicationSlice = createSlice({
  name: "application",
  initialState: initialApplicationState,
  reducers: {
    toggleSnack: (
      state,
      action: {
        payload: SnackType;
      }
    ) => {
      state.snackContent = action.payload;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    setActiveBooking: (state, action) => {
      state.activeBooking = action.payload;
    },
    setInstitutionServices: (state, action) => {
      state.institutionServices = action.payload;
    },
    setInstitutionDoctors: (state, action) => {
      state.institutionDoctors = action.payload;
    },
    setInstitutionLocations: (state, action) => {
      state.institutionLocations = action.payload;
    },
    setActiveDoctor: (state, action) => {
      state.activeDoctor = action.payload;
    },
    setActiveFacility: (state, action) => {
      state.activeFacility = action.payload;
    },
    setDeviceId: (state, action) => {
      state.deviceId = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    
    setSpecialties: (state, action) => {
      state.specialties = action.payload;
    },
    initializeUser: (state, action) => {
      state = initialApplicationState;
    },
  },
});

export const {
  setSpecialties,
  setActiveFacility,
  setActiveDoctor,
  setDeviceId,
  toggleSnack,
  initializeUser,
  setActiveBooking,
  setActivePage,
  setInstitutionServices,
  setInstitutionDoctors,setCart,setInstitutionLocations,
} = applicationSlice.actions;
export default applicationSlice.reducer;
