import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ArrowLeft, Calendar, Loader, MapPin, Star } from "react-feather";
// import { format } from "date-fns";
import { TailSpin } from "react-loader-spinner";
import { DDGColors } from "../../../constants/colors";
import { AppState } from "../../../state";
import { getInitials } from "../../../functions";
import BookingSteps from "../../../components/BookingSteps";
import { useSwitchPage } from "../../../state/features/applicationSlice/hooks";
import { ApplicationPages } from "../../../state/features/applicationSlice";
import Image from "../../../components/Image";
interface startProps {}
const ServiceStart: React.FC<startProps> = ({}) => {
  const { activeBooking, activeFacility: hospital } = useSelector<
    AppState,
    AppState["application"]
  >((state) => state.application);

const router = useSwitchPage()
if(!hospital){
  return  <div style={{minHeight : 500}} className="relative flex items-center justify-center flex-col flex-grow w-full h-full max-w-lg mx-auto overflow-y-hidden min-h-[300px]"><TailSpin width="20"  color={DDGColors.primary} /></div>
}
  return (
    <main className="h-full overflow-y-scroll flex flex-col bg-[#F6F9F9]">

        <div className="py-4 pt-8 md:pt-4 pr-4 bg-[#F6F9F9]">
          <div className="flex flex-row items-center ">
            <button
              onClick={() => {
                router(ApplicationPages.HOME);
              }}
              className="p-3 rounded-full"
            >
              <ArrowLeft color="#292D32" size={14} />
            </button>
            <div className="relative flex flex-row items-center justify-center flex-shrink-0 w-8 h-8 mr-2 text-sm text-white rounded-full cursor-pointer bg-primary">
              <Image
              useBase={false}
                className="flex-1 w-full h-full bg-white border rounded-full p-dark border-primaryLight"
                src={hospital.details.logoImage ?? ''}
                alt={hospital.details.institutionName}
              />
            </div>
            <div className="flex-1">
              <p className="text-sm font-bold text-left capitalize">
                {hospital.details.institutionName}
              </p>
              <div className="flex flex-row items-center space-x-1">
                <MapPin size={12} color={'#667085'} />
                <p className="text-[10px] text-[#667085]">
                  {hospital?.details?.address?.city ?? ""},{" "}
                  {hospital?.details?.address?.country ?? ""}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-end space-x-2">
              <Star
                size={12}
                color={DDGColors.primary}
                fill={DDGColors.primary}
              />
              <p className="text-xs font-bold">5.0</p>
            </div>
          </div>
        </div>

      <div className="flex flex-col flex-1 space-y-4 bg-white shadow-t-xl shadow-gray700 rounded-t-2xl">
        <BookingSteps type="SERVICE_BOOKING" />
        <div className="flex flex-row items-center pt-1 pb-3 mx-auto space-x-1 ">
          {/* <p className="text-[10px] text-gray-500 ">Powered by</p> <Logo /> */}
        </div>
      </div>
    </main>
  );
};
export default ServiceStart;
