export enum DDGColors {
    dark = 'rgb(51,51,51)',
    primary = '#034a47',
    primaryAlt = '#041367',
    secondary = '#fece00',
    secondaryAlt = '#efed70',
    primaryLight = '#EDFBF8',
    secondaryLight = '#F0ECF7',
    blood = '#9f333b',
    lightBlood = '#f7e0e1',
    wildSand = '#f5f5f5',
    gainsboro = '#DCDCDC',
  }
  