import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  Doctor,
  Patient,
  PaymentMethod,
  Transaction,
  UserData,
} from "../../../types/User";


export interface AuthState {
  User: Patient | null;
  Account: UserData | null;
  isLoggedIn: boolean | null;
  myProfiles: Patient[];
  PaymentMethods: PaymentMethod[];
  activeMemberId: string | null;
}

const initialState: AuthState = {
  User: null,
  Account: null,
  isLoggedIn: null,
  myProfiles: [],
  activeMemberId: null,
  PaymentMethods: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
  
    setActiveMemberId: (state, action) => {
      state.activeMemberId = action.payload;
    },
    setUser: (state, action) => {
      // console.log('setting user here',action.payload)
      state.Account = action.payload;
      state.isLoggedIn = true;
    },
    setUserProfile: (state, action) => {
      // console.log('setting user here',action.payload)
      state.User = action.payload;
      // state.isLoggedIn = true;
    },
   
    setMyProfiles: (state, action) => {
      state.myProfiles = action.payload;
    },
    setPaymentMethods: (state, action) => {
      state.PaymentMethods = action.payload;
    },
    signOutUser: (state, action) => {
      state.User = null;
      state.Account = null;
      state.isLoggedIn = false;
      state.activeMemberId = null;
      state.myProfiles = []
    },
    initializeUser: (state) => {
      state = initialState;
    },
  },
});
export const {
 
  setPaymentMethods,
 
  setActiveMemberId,
  setMyProfiles,
  setUser,
  setUserProfile,
  signOutUser,
  initializeUser,
} = authSlice.actions;
export default authSlice.reducer;
