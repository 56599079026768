import axios, { AxiosError, AxiosInstance } from 'axios'

export const API_KEY = process.env.REACT_APP_DDG_API_KEY
const baseURL = process.env.REACT_APP_BASE_URL

const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL,
  })
  instance.interceptors.request.use(
    async (config) => {
      if (config && config.headers) {
        config.headers['ddg-api-key'] = API_KEY ?? ''
      }
      return config
    },
    (err) => {
      return Promise.reject(err)
    },
  )

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        console.log('error', error)
      }
      return Promise.reject(error)
    },
  )

  return instance
}

export const axiosClient = createAxiosInstance()
