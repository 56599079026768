// import './output.css'
import Main from "./Main";
import store, { persistor } from "./state";
import { PersistGate } from "redux-persist/integration/react";
import { Provider as ReduxProvider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import Updater from "./state/features/authSlice/updater";
import ErrorComponent from "./components/Error/ErrorComponent";
function App({ isPopUp, apiData }: { isPopUp: string; apiData: string }) {
  return (
    <div className="">
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {() => (
            <>
              <Updater />
              <ErrorBoundary
                FallbackComponent={ErrorComponent}
              >
                <Main isPopUp={isPopUp} apiData={apiData} />
              </ErrorBoundary>
              <ToastContainer className="!z-[99999]" />
            </>
          )}
        </PersistGate>
      </ReduxProvider>
    </div>
  );
}

export default App;
