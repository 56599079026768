import React, { useEffect, useState } from "react";
import {
  Copy,
  Home,
  Link as LinkIcon,
  MapPin,
  MessageCircle,
  Star,
  Video,
  X,
} from "react-feather";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { VisitType } from "../../types/User";
import { AppState } from "../../state";
import useCopy from "../../config/useCopy";
import { DDGColors } from "../../constants/colors";
import { useSwitchPage } from "../../state/features/applicationSlice/hooks";
import { ApplicationPages } from "../../state/features/applicationSlice";
import { getInitials } from "../../functions";
import Image from "../../components/Image";

interface SuccessProps {}

const Success: React.FC<SuccessProps> = ({}) => {
  const [booking, setBooking] = useState<{
    time: number;
    visitType: VisitType;
    url: string;
  }>();
  const { activeDoctor } = useSelector<
    AppState,
    AppState["application"]
  >((state) => state.application);
  const { handleCopy } = useCopy();
  const urlParams = new URLSearchParams(window.location.search);
  const queryTime = urlParams.get("time");
  const queryAid = urlParams.get("aid");
  const queryCid = urlParams.get("cid");
  const router = useSwitchPage();
  useEffect(() => {
    if (queryTime) {

      setBooking({
        time: parseFloat(queryTime as string),
        visitType: (queryTime as VisitType) ?? VisitType.VIRTUAL,
        url: `https://www.drdogood.health/dashboard/consultations?cid=${queryCid}&aid=${queryAid}`,
      });
    }
  }, [queryTime]);
  if (!booking) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <TailSpin width={20} height={20} color={DDGColors.primary} />
      </div>
    );
  }
  return (
    <div className="flex flex-col flex-1 h-full text-left">
      <div>
        <div>
          <Image
          useBase
          alt=""
            src="/img/success.png"
            className="h-32 w-full bg-cover bg-center bg-no-repeat bg-[#257C6C]"
          />
        </div>
        <button
          onClick={() => {
            router(ApplicationPages.HOME);
          }}
          className="absolute p-2 bg-gray-100 rounded-full right-5 top-4 bg-opacity-30"
        >
          <X size={14} color="white" />
        </button>
      </div>
      <div className="flex flex-col flex-1 px-3 py-6 space-y-6">
        <div>
          <p className="text-xs text-primary">Congratulations 🎉</p>
          <p className="text-lg font-bold">Appointment Booked!</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="relative flex flex-row items-center justify-center flex-shrink-0 w-12 h-12 mr-2 text-sm text-white rounded-full cursor-pointer bg-primary">
            {activeDoctor?.doctor?.profileImage ? (
              <Image
              useBase={false}
                alt={activeDoctor?.doctor?.firstName}
                src={activeDoctor?.doctor?.profileImage}
                className="flex-1 w-full h-full rounded-full"
              />
            ) : (
              <p>
                {getInitials(
                  activeDoctor?.doctor?.firstName +
                    " " +
                    activeDoctor?.doctor?.lastName
                )}
              </p>
            )}
          </div>
          <div className="">
            <div>
              <p className="text-sm font-semibold capitalize text-gray900">
                Dr.{" "}
                {activeDoctor?.doctor?.firstName +
                  " " +
                  activeDoctor?.doctor?.lastName}
              </p>
            </div>
            <p className="text-[11px] text-[#475467]">
              {activeDoctor?.doctor?.specialtyData?.title}
            </p>
          </div>
        </div>
        <div className="rounded-md text-[10px] border border-[#D6D6D6] bg-[#F6F6F6] border-opacity-20">
          <div className="flex mx-2 py-4 flex-row items-center space-x-4 border-b border-[#D6D6D6] border-opacity-40">
            <div className="bg-white p-2 border-[#D6D6D6]  border rounded-md ">
              {booking.visitType === VisitType.VIRTUAL ? (
                <Video size={12} color={DDGColors.primary} />
              ) : booking.visitType === VisitType["IN-PERSON"] ? (
                <MapPin size={12} color={DDGColors.primary} />
              ) : (
                <Home size={12} color={DDGColors.primary} />
              )}
            </div>
            <div className=" text-[#667085] space-y-1">
              <p>
                {/* {format(new Date(booking?.time), "dd/MM/yyyy 'at' h:mm a")} */}

                {new Date(new Date(booking?.time) ?? 0).toUTCString()}
              </p>
              <p className="lowercase">
                The duration of your {booking.visitType} visit is 20 minutes.
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center px-2 py-4 space-x-4 ">
            <div className="bg-white p-2 border-[#D6D6D6]  border rounded-md ">
              <LinkIcon size={12} color={DDGColors.primary} />
            </div>
            <div className=" text-[#667085] space-y-1">
              <p>Use the link below for your consultation</p>
              <button
                onClick={() => {
                  handleCopy(booking.url);
                }}
                className="flex flex-row items-center pr-4 space-x-1 text-left"
              >
                <p className="text-start text-[8px]">{booking.url}</p>
                <Copy size={18} color={DDGColors.primary} />
              </button>
            </div>
          </div>
        </div>
        <div className=" px-2 border-t border-[#D6D6D6]  border-opacity-50 pt-2">
          <p className="text-xs font-semibold text-gray-400">Tip:</p>
          <p className="text-xs pt-2 text-[#667085]">
            - Be on the lookout for a reminder 10 minutes before your
            appointment time.
          </p>
          <div className="relative flex flex-col">
            <p className="text-xs pt-2 text-[#667085]">
              - You can start chatting with your provider on the app
            </p>
            <div className="relative flex flex-row my-3 space-x-4">
              <a
                target="_blank"
                href="https://apps.apple.com/gh/app/drdogood/id6450893844"
                rel="noreferrer"
              >
                <div className="relative h-5">
                  <Image
                  useBase
                    src="/icons/apple.svg"
                    loading="lazy"
                    alt="Apple AppStore"
                    className="h-full"
                  />
                </div>
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=app.drdogood"
                rel="noreferrer"
              >
                <div className="relative h-5">
                  <Image
                  useBase
                    src="/icons/google-play.svg"
                    loading="lazy"
                    alt="Google Play Store"
                    className="h-full"
                  />
                </div>
              </a>
            </div>
            <div className="relative">
              <Image
              useBase
                src="/img/qr-download.png"
                loading="lazy"
                alt=""
                className="w-10 h-auto "
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "rgba(246, 249, 249,0.5)" }}
        className={`
      duration-400 bottom-0 md:absolute fixed px-4  w-full self-stretch  pb-4   text-dark `}
      >
        <a href="https://link.drdogood.health/app" target="blank">
          <div className="flex items-center justify-center w-full p-3 space-x-2 rounded-md bg-primary">
            <MessageCircle color={DDGColors.primaryLight} size={20} />
            <p className="text-xs font-bold text-primaryLight">
              Chat with provider
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};
export default Success;
