import React, { useEffect, useState } from "react";
import {
  Copy,
  Home,
  Link as LinkIcon,
  Map,
  MapPin,
  MessageCircle,
  Star,
  Video,
  X,
} from "react-feather";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { VisitType } from "../../types/User";
import { AppState } from "../../state";
import useCopy from "../../config/useCopy";
import { DDGColors } from "../../constants/colors";
import { useSwitchPage } from "../../state/features/applicationSlice/hooks";
import { ApplicationPages } from "../../state/features/applicationSlice";
import { formatNumberScale, getInitials } from "../../functions";
import Image from "../../components/Image";
import { Location } from "../../types/HealthInstitution";

interface SuccessProps {}

const ServiceSuccess: React.FC<SuccessProps> = ({}) => {
  const [booking, setBooking] = useState<{
    time: number;
    location: Location;
  }>();
  const { activeFacility, institutionLocations, cart } = useSelector<
    AppState,
    AppState["application"]
  >((state) => state.application);
  const { handleCopy } = useCopy();
  const urlParams = new URLSearchParams(window.location.search);
  const queryTime = urlParams.get("time");
  const type = urlParams.get("type");
  const location = urlParams.get("location");

  const router = useSwitchPage();
  useEffect(() => {
    if (queryTime) {
      setBooking({
        time: parseFloat(queryTime as string),
        location: institutionLocations.find((loc) => loc.id === location)!,
      });
    }
  }, [queryTime]);
  if (!booking || !activeFacility) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <TailSpin width={20} height={20} color={DDGColors.primary} />
      </div>
    );
  }
  return (
    <div className="flex flex-col flex-1 h-full text-left">
      <div>
        <div>
          <Image
            useBase
            alt=""
            src="/img/success.png"
            className="h-32 w-full bg-cover bg-center bg-no-repeat bg-[#257C6C]"
          />
        </div>
        <button
          onClick={() => {
            router(ApplicationPages.HOME);
          }}
          className="absolute p-2 bg-gray-100 rounded-full right-5 top-4 bg-opacity-30"
        >
          <X size={14} color="white" />
        </button>
      </div>
      <div className="flex flex-col flex-1 px-3 py-6 pb-16 space-y-6">
        <div>
          <p className="text-xs text-primary">Congratulations 🎉</p>
          <p className="text-lg font-bold">Appointment Booked!</p>
        </div>
        <div className="flex flex-row items-center ">
            <div className="relative flex flex-row items-center justify-center flex-shrink-0 w-10 h-10 p-1 mr-2 text-sm text-white rounded-full cursor-pointer bg-gainsboro">
              <Image
              useBase={false}
                className="flex-1 w-full h-full bg-white rounded-full p-dark border-primaryLight"
                src={activeFacility.details.logoImage ?? ''}
                alt={activeFacility.details.institutionName}
              />
            </div>
            <div className="flex-1">
              <p className="text-sm font-bold text-left capitalize">
                {activeFacility.details.institutionName}
              </p>
              <div className="flex flex-row items-center space-x-1">
                <MapPin size={12} color={'#667085'} />
                <p className="text-[10px] text-[#667085]">
                  {activeFacility?.details?.address?.city ?? ""},{" "}
                  {activeFacility?.details?.address?.country ?? ""}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center p-2.5 rounded-full bg-primary">
                <Map fill={DDGColors.primaryLight} size={14} color={DDGColors.primary} />
            </div>
          </div>
        <div className="rounded-md text-[10px] border border-[#D6D6D6] bg-[#F6F6F6] border-opacity-20 px-4">
          {[...cart].map((item, index) => {
            return (
              <div
                key={index}
                className={`flex flex-row items-center  py-4 ${
                  index === 0
                    ? ""
                    : "border-[#D6D6D6] border-opacity-50 border-t"
                }`}
              >
                <div className="">
                  <Image
                    className="object-cover w-[44px] h-[44px] rounded-lg"
                    useBase={false}
                    src={Boolean(item.image) ? item.image!: activeFacility.details.coverImage?? "/img/surgery.png"}
                    alt=""
                  />
                </div>
                <div className="flex flex-col flex-1 px-3 space-y-1">
                  <p className="text-xs font-semibold">{item.name}</p>
                </div>
                <div>
                  <p className="text-xs font-semibold">
                    ₵{formatNumberScale(Number(item.price) + 15)}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className=" px-2 border-t border-[#D6D6D6]  border-opacity-50 pt-2">
          <p className="text-xs font-semibold text-gray-400">Tip:</p>
          <p className="text-xs pt-2 text-[#667085]">
            - Be on the lookout for a reminder 10 minutes before your
            appointment time.
          </p>
          <div className="relative flex flex-col">
            <p className="text-xs pt-2 text-[#667085]">
              - You can make updates to your appointment on the app
            </p>
            <div className="relative flex flex-row my-3 space-x-4">
              <a
                target="_blank"
                href="https://apps.apple.com/gh/app/drdogood/id6450893844"
                rel="noreferrer"
              >
                <div className="relative h-5">
                  <Image
                    useBase
                    src="/icons/apple.svg"
                    loading="lazy"
                    alt="Apple AppStore"
                    className="h-full"
                  />
                </div>
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=app.drdogood"
                rel="noreferrer"
              >
                <div className="relative h-5">
                  <Image
                    useBase
                    src="/icons/google-play.svg"
                    loading="lazy"
                    alt="Google Play Store"
                    className="h-full"
                  />
                </div>
              </a>
            </div>
            <div className="relative">
              <Image
                useBase
                src="/img/qr-download.png"
                loading="lazy"
                alt=""
                className="w-10 h-auto "
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "rgba(246, 249, 249,0.5)" }}
        className={`
      duration-400 bottom-0 md:absolute fixed px-4  w-full self-stretch  pb-4   text-dark `}
      >
        <a href="https://link.drdogood.health/app" target="blank">
          <div className="flex items-center justify-center w-full p-3 space-x-2 rounded-md bg-primary">
            <p className="text-xs font-bold text-primaryLight">
           Continue
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};
export default ServiceSuccess;
