import { FC, useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { DDGColors } from "../../constants/colors";
import {
  ArrowRight,
  Book,
  ChevronLeft,
  ChevronRight,
  Eye,
  Heart,
  Loader,
  Map,
  MapPin,
  Minus,
  MinusCircle,
  PlusCircle,
  Search,
  Share,
  ShoppingCart,
  Star,
} from "react-feather";
import { AppState } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import {
  useSwitchPage,
  useUpdateCart,
} from "../../state/features/applicationSlice/hooks";
import { ApplicationPages, setCart } from "../../state/features/applicationSlice";
import Image from "../../components/Image";
import { formatNumberScale } from "../../functions";

interface homeProps {
  isPopUp: boolean
}

const Home: FC<homeProps> = ({isPopUp}) => {
  const {
    activeFacility: hospital,
    institutionServices,
    institutionDoctors,
    cart,
  } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );
  const router = useSwitchPage();
  const updateCart = useUpdateCart();
  const dispatch = useDispatch()
  const [activeCategory, setActiveCategory] = useState("");
  if (!hospital) {
    return (
      <div
        style={{ minHeight: 500 }}
        className="relative flex items-center justify-center flex-col flex-grow w-full h-full max-w-lg mx-auto overflow-y-hidden min-h-[300px]"
      >
        <RotatingLines width="20" strokeColor={DDGColors.gainsboro} />
      </div>
    );
  }
  return (
    <main className="relative flex flex-col overflow-y-scroll bg-white">
      <div className="flex-1">
        <div className="relative h-32">
          <div className="relative h-32">
            <Image
              useBase={false}
              className="object-cover object-top w-full h-32 bg-no-repeat"
              src={hospital.details.coverImage ?? ""}
              alt="cover"
            />
          </div>
          <div className="absolute flex flex-row items-center justify-end w-full h-auto px-4 space-x-3 top-4">
            <div className="">
              <button
                className="flex items-center justify-center p-2 bg-gray-200 rounded-full"
                // onClick={() => navigation.goBack()}
              >
                <Search size={14} color={DDGColors.primary} />
              </button>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <button
                className="flex flex-row items-center p-2 bg-gray-100 rounded-full"
                onClick={async () => {
                  // await Share.share(
                  //   {
                  //     message: `Book ${hospital.details.institutionName} on DrDoGood 🌟🥤`,
                  //     url: `app.drdogood://institution/${hospital.id}`,
                  //     title: `DrDoGood | ${hospital.details.institutionName}`,
                  //   },
                  //   {}
                  // )
                }}
              >
                <Share size={14} color={DDGColors.dark} />
              </button>
            </div>
          </div>
          <div className="absolute bottom-0 z-40 w-full h-4 bg-white rounded-t-xl"></div>
        </div>
        <div className="h-auto bg-white  rounded-t-lg z-200000 border-b-0.5 border-gray-300">
          <button
            onClick={() => {}}
            className="flex flex-row w-full h-auto px-4 pb-4"
          >
            <div className="flex items-center justify-center w-10 h-10 mr-4 rounded-full ">
              <Image
                useBase={false}
                className="flex-1 w-full h-full bg-white border rounded-full p-dark border-primaryLight"
                src={hospital.details.logoImage ?? ""}
                alt={hospital.details.institutionName}
              />
            </div>
            <div className="flex-1">
              <p className="font-bold text-left capitalize">
                {hospital.details.institutionName}
              </p>
              <div className="flex flex-row items-center">
                <MapPin size={14} color={DDGColors.dark} />
                <p className="text-[10px] text-[#667085]">
                  {hospital?.details?.address?.city ?? ""},{" "}
                  {hospital?.details?.address?.country ?? ""}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-end space-x-2">
              <Star
                size={12}
                color={DDGColors.primary}
                fill={DDGColors.primary}
              />
              <p className="text-xs font-bold">5.0</p>
            </div>
          </button>
        </div>
        <div className="">
          <div className="p-3 border-b border-gray-100">
            <p className="pb-2 text-xs font-semibold text-gray700">About us</p>
            <p className="text-xs font-medium text-gray900 line-clamp-4">
              {hospital.details.description}
            </p>
          </div>
          {hospital.details.institutionType === "hospital" && (
            <div className="pt-4 border-b border-gray-100 ">
              <p className="px-4 text-xs font-semibold text-gray700">Specialties</p>
              <div className="flex flex-row items-center px-4 pb-4 mt-4 space-x-2 overflow-x-scroll">
                {hospital.practiceSpecialties.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className="px-4 py-2 border border-[#D6D6D6] rounded-md"
                    >
                      <div className="flex items-center space-x-3">
                        <div className="flex items-center justify-center w-4 h-4 rounded-full">
                          {/* {specialtyIcon(item)} */}
                          <Eye className="text-[10px]" />
                        </div>
                        <p className="text-[10px] flex font-medium text-gray900 whitespace-nowrap">
                          {item}
                        </p>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          )}
          {[...institutionDoctors].length > 0 && (
            <button
              onClick={() => {
                router(ApplicationPages.SPEAK_TO_DOCTOR);
              }}
              className="flex flex-row items-center w-full p-4 border-b border-gray-100 "
            >
              <div className="p-2 bg-[#F0FFFE] rounded-full">
                <Image useBase src="/icons/heart.svg" alt="" />
              </div>
              <div className="flex-1 px-3 text-left">
                <p className="font-medium text-[#101828] text-xs">
                  Speak to a doctor
                </p>
                <p className="text-[10px] text-[#667085]">
                  Connect with a Healthcare Professional Instantly for Your
                  Health Concerns
                </p>
              </div>
              <button className="p-2 rounded-full">
                <ChevronRight className="text-gray700" />
              </button>
            </button>
          )}
          <div className="py-4 border-gray-100">
            <p className="px-4 text-xs font-semibold text-gray700">
              Healthcare Services
            </p>
            <div className="mt-4">
              <div className="relative">
                <div className={`absolute bottom-0 w-full h-1 bg-gray-300 `} />
                <div className="flex flex-row items-center px-4 space-x-3 overflow-x-scroll " style={{
                  scrollbarWidth: 'none',
                }}>
                  <button
                    onClick={() => setActiveCategory("")}
                    className="relative w-auto h-12 px-4 pb-2"
                  >
                    <p
                      className={`${
                        !activeCategory ? "text-primary" : "text-dark"
                      } text-[10px] font-bold uppercase`}
                    >
                      All
                    </p>
                    <div
                      className={`absolute left-0 bottom-0 w-full px-4 ${
                        !activeCategory ? "bg-primary" : ""
                      }  h-1 rounded-full `}
                    />
                  </button>
                  {[...(hospital?.serviceCategories ?? [])].map((category) => {
                    return (
                      <button
                        onClick={() => setActiveCategory(category.id)}
                        className="relative h-12 px-4 pb-2 w-72"
                      >
                        <p
                        style={{    whiteSpace: 'nowrap'
                        }}
                          className={`${
                            activeCategory === category.id
                              ? "text-primary"
                              : "text-dark"
                          } text-[8px] font-bold uppercase white-space: nowrap;
`}
                        >
                          {category.name}
                        </p>

                        <div
                          className={`left-0 ${
                            activeCategory === category.id ? "bg-primary" : ""
                          } w-full self-end h-1 absolute -bottom-0 rounded-full`}
                        />
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className="my-4">
                {hospital?.details.institutionType && (
                  <div className="flex flex-row flex-wrap justify-center">
                    {[...(institutionServices ? institutionServices : [])]
                      .filter(
                        (item) =>
                          (item.category === activeCategory ||
                            !activeCategory) &&
                          item.cost
                      )
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((service) => {
                        const isChecked = cart?.some(
                          (item) => service.id === item.id
                        );

                        return (
                          <button  onClick={() => {
                            updateCart([service]);
                          }} className={`relative flex flex-col ${isPopUp?'w-1/2':'w-[180px]'} p-2 overflow-hidden text-left h-52`}>
                            <div className="">
                              <Image
                                useBase={false}
                                className="object-cover w-full h-20 rounded-t-xl"
                                src={Boolean(service.image) ? service.image!: hospital.details.coverImage?? "/img/surgery.png"}
                                alt=""
                              />
                            </div>
                            <div className="flex flex-1 w-full flex-col justify-between p-2 pb-0 -mt-2 bg-white rounded-lg border border-opacity-25 border-[#667085]">
                              <div className="">
                                <p className="text-xs font-medium">
                                  {service.title}
                                </p>
                                <p className="text-[10px] line-clamp-2 text-[#667085]">
                                  {service.description}
                                </p>
                              </div>
                              <div className="flex flex-row items-center justify-between py-1 pt-4 ">
                                <p className="text-xs font-semibold">
                                  ₵{Number(service.cost) + 15}
                                </p>
                                <button
                                  className="p-2"
                                 
                                >
                                  {isChecked ? (
                                    <MinusCircle
                                      size={14}
                                      color={DDGColors.lightBlood}
                                    />
                                  ) : (
                                    <PlusCircle
                                      size={14}
                                      color={DDGColors.primary}
                                    />
                                  )}
                                </button>
                              </div>
                            </div>
                          </button>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {cart?.length > 0 && (
        <button
          onClick={() => {
            router(ApplicationPages.BOOKING_SERVICE_START);
          }}
          className="sticky z-40 self-stretch w-full h-10 px-4 shadow-3xl bottom-5 "
        >
          <div className="relative flex items-center justify-between h-full flex-1 bg-[#1F2223] rounded-lg py-3 px-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center w-4 h-4 bg-white rounded-full -top-2 right-1">
                <p className="text-[10px] text-dark">{cart?.length}</p>
              </div>
              <p className="text-xs font-semibold text-white">Checkout</p>
            </div>
            <div className="flex space-x-2">
              <p className="text-xs font-semibold text-white">
                {formatNumberScale(
                  [...cart].reduce(
                    (acc, val) => acc + (Number(val.price) + 15),
                    0
                  ),
                  true
                )}
              </p>
              <button onClick={()=>{
                dispatch(setCart([]))
              }}><MinusCircle
                                      size={14}
                                      color={DDGColors.lightBlood}
                                    /></button>
            </div>
          </div>
        </button>
      )}
    </main>
  );
};

export default Home;

// const specialtyIcon = (specialty) => {
//   switch (specialty) {
//     case "Dentistry":
//       return (
//         <FontAwesome5 color={DDGColors.primary} name="teeth" size={18} />
//       );
//     case "Neurology":
//       return (
//         <FontAwesome5 color={DDGColors.primary} name="brain" size={18} />
//       );
//     case "Optometry/Ophthalmology":
//       return <Eye color={DDGColors.primary} name="eye" size={18} />;
//     case "Pediatric":
//       return <Babuy color={DDGColors.primary} name="baby" size={18} />;
//     case "Obstetrics/Gynecology":
//       return (
//         <MaterialCommunityIcons
//           color={DDGColors.primary}
//           name="mother-nurse"
//           size={18}
//         />
//       );
//     case "Primary Care":
//       return (
//         <MaterialCommunityIcons
//           color={DDGColors.primary}
//           name="mother-nurse"
//           size={18}
//         />
//       );
//     case "Cardiology":
//       return (
//         <FontAwesome5 color={DDGColors.primary} name="heart" size={18} />
//       );
//     default:
//       return (
//         <MaterialIcons
//           color={DDGColors.primary}
//           name="health-and-safety"
//           size={18}
//         />
//       );
//   }
// };
