import { FieldValue, Timestamp } from 'firebase/firestore'
import { AppointmentDoctorData, AppointmentPatientData } from './Requests/ConsultationRequest'

export interface Consultation {
  complaints: string[]
  id: string
  patientData: AppointmentPatientData
  doctorData: AppointmentDoctorData
  state: 'PENDING' | 'ACTIVE' | 'ABANDONED' | 'CLOSED' | 'REVIEW' | 'REJECTED'
  symptoms: string
  diagnosis: string
  startTime: Timestamp
  isReviewConsultation: boolean
  previousConsultationId: string
  type: string
  initAppointmentId?: string
  history: {
    [key: string]: ConsultationHistory
  }
}
export interface MessageData {
  id: string
  uid: string
  displayName: string
  text: string
  timestamp: any
  cid: string
  isRead: boolean
  isBot?: {
    message: string
    type:
      | 'APPOINTMENT_STARTED'
      | 'APPOINTMENT_ENDED'
      | 'CONSULTATION_PENDING'
      | 'CONSULTATION_ENDED'
      | 'APPOINTMENT_REQUESTED'
      | 'APPOINTMENT_ACCEPTED'
  }
}
export interface ConsultationHistory {
  symptoms: string
  diagnosis: string
  prescriptions: Prescription[]
  remarks: string
  timestamp: Timestamp
  appointmentId?: string
}
export interface Prescription {
  dosage_form: 'TABLET' | 'CAPSULE' | 'SYRUP' | 'INJECTABLE' | 'SPRAY' | 'GUM, CHEWING' | 'CREAM, INSERT'
  route: 'TOPICAL' | 'ORAL' | 'INJECTION' | 'INTRAVENOUS' | 'INHALATION' | 'NASAL' | 'BUCCAL'
  drug_name: string
  drug_id: string
  duration: Duration
  frequency: Duration
  note: string
}
interface Duration {
  period: 'Month' | 'Day' | 'Year' | 'Week' | 'Hour'
  quantity: number
}

export interface Appointment {
  consultationId: string
  type: 'NEW_CONSULTATION' | 'FOLLOWUP_CONSULTATION'
  endTime: Timestamp
  id: string
  patientData: AppointmentPatientData
  doctorData: AppointmentDoctorData
  startTime: Timestamp
  status: 'PENDING' | 'ACCEPTED' | 'ACTIVE' | 'ENDED' | 'CANCELED' | 'AWAITING_REVIEW' | 'NO_SHOW'
  requestedBy: 'patient' | 'doctor'
  medium: 'VIRTUAL' | 'IN-PERSON' | 'CONCIERGE'
  review: { id: string; rating: number }
  createdAt: Timestamp
  updatedAt: Timestamp
  patientJoined?: boolean
}
export interface Review {
  id?: string
  rating: number
  feedback: string
  waitTimeRating?: number
  mannerRating?: number
  patientData: AppointmentPatientData
  doctorData: AppointmentDoctorData
  doctorId?: string;
  appointmentId: string
  createdAt: Timestamp
}

export enum CareType {
  'physical_therapy',
  'primary',
  'drug_therapy',
  'specialist',
}
export enum BookingSource {
  WEB = "WEB",
  BOOKING_PAGE='BOOKING_PAGE',
  APP= 'APP'
}