import React, { Dispatch, SetStateAction, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { DDGColors } from "../../constants/colors";

interface StepTwoProps {
  loading: boolean;
  createAccount: (data: any) => void;
}

export function StepTwo({ loading, createAccount }: StepTwoProps) {
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div>
      <div className="my-4">
        <p className="text-lg font-bold text-gray900">
          Let’s Get more information about you!
        </p>
        <p className="text-xs text-gray500">
          Kindly enter your Name, Gender and Date of Birth
        </p>
      </div>
      <div className="space-y-4">
        <div className="flex flex-row items-center space-x-4">
          <div className="relative w-1/2">
            <p className="text-[10px] font-semibold text-gray700">First Name</p>
            <input
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstName}
              className="border text-gray500 w-full text-xs py-2 rounded-md shadow-sm border-[#D0D5DD] px-2 mt-2 outline-none"
              placeholder="Enter your first name"
            />
          </div>
          <div className="relative w-1/2">
            <p className="text-[10px] font-semibold text-gray700">last Name</p>
            <input
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              value={lastName}
              className="border text-gray500 w-full text-xs py-2 rounded-md shadow-sm border-[#D0D5DD] px-2 mt-2 outline-none"
              placeholder="Enter your last name"
            />
          </div>
        </div>
        <div className="flex flex-row items-center space-x-4">
          <div className="relative w-1/2">
            <p className="text-[10px] font-semibold text-gray700">Gender</p>
            <select
              className="border w-full text-xs text-gray500 py-2 rounded-md shadow-sm bg-transparent border-[#D0D5DD] px-2 mt-2 outline-none"
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div className="relative w-1/2">
            <p className="text-[10px] font-semibold text-gray700">
              Date of birth
            </p>
            <input
              className="border w-full text-xs py-2  text-gray500 rounded-md shadow-sm border-[#D0D5DD] px-2 mt-2 outline-none"
              type="date"
              id="dob"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>

        </div>
        <div>
          <p className="text-[10px] font-semibold text-gray700">
           Password
          </p>
          <input
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            className="border text-gray500 w-full text-xs py-2 rounded-md shadow-sm border-[#D0D5DD] px-2 mt-2 outline-none"
            placeholder="Password ****"
          />
        </div>
        <div>
          <p className="text-[10px] font-semibold text-gray700">
            Email (Optionial)
          </p>
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            className="border text-gray500 w-full text-xs py-2 rounded-md shadow-sm border-[#D0D5DD] px-2 mt-2 outline-none"
            placeholder="Enter your email"
          />
        </div>
        <button
          // disabled={loading || phoneVerificationLoading}
          onClick={() => {
            createAccount({ gender, firstName,password, lastName, dob, ...(email && {email}) });
          }}
          className="flex items-center justify-center w-full py-3 mt-8 text-xs font-bold text-white rounded-md bg-primary"
        >
          {loading ? (
            <RotatingLines width="20" strokeColor={DDGColors.gainsboro} />
          ) : (
            <p className="text-xs font-semibold text-white">Continue</p>
          )}
        </button>
      </div>
    </div>
  );
}
