import React, { useCallback, useRef } from 'react'

interface OtpInputProps {
  length?: number
  onSubmit: (otp: string) => void
  otp: string[]
  setOtp: React.Dispatch<React.SetStateAction<string[]>>
  isGrayBorder?: boolean
  alignLeft?: boolean
}

const OtpInputForm: React.FC<OtpInputProps> = ({ length = 4, onSubmit, otp, setOtp, alignLeft }) => {
  const inputRefs = useRef<HTMLInputElement[]>([])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const value = event.target.value
      if (/^\d*$/.test(value)) {
        // Allow only digits
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp]
          newOtp[index] = value
          return newOtp
        })
        // Move to next input if current input is filled
        if (value && index < length - 1) {
          inputRefs.current[index + 1].focus()
        }
      }
    },
    [length, setOtp],
  )

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && index > 0) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp]
        newOtp[index] = '' // Clear current field
        return newOtp
      })
      inputRefs.current[index - 1].focus()
    }
  }, [])

  // allow users to patse from clipboard
  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedText = event.clipboardData.getData('text')
      const pastedOtp = pastedText.slice(0, length).split('')

      setOtp((prevOtp) => {
        const newOtp = [...prevOtp]
        for (let i = 0; i < length; i++) {
          newOtp[i] = pastedOtp[i] || ''
        }
        return newOtp
      })

      // Focus on the next empty input or the last input
      const nextIndex = pastedOtp.length < length ? pastedOtp.length : length - 1
      inputRefs.current[nextIndex].focus()
    },
    [length],
  )

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const otpString = otp.join('')
      onSubmit(otpString)
    },
    [onSubmit, otp],
  )

  return (
    <form onSubmit={handleSubmit} className={`flex ${alignLeft ? '' : 'justify-center'}  w-full`}>
      <div className='flex flex-wrap lg:gap-1'>
        {Array.from({ length }, (_, index) => (
          <input
            key={index}
            type='tel'
            inputMode='numeric'
            placeholder='0'
            value={otp[index] || ''}
            ref={(ref) => (inputRefs.current[index] = ref as any)}
            onChange={(event) => handleChange(event, index)}
            onKeyDown={(event) => handleKeyDown(event, index)}
            onPaste={handlePaste}
            maxLength={1}
            autoComplete='off'
            autoFocus={index === 0}
            className='m-2 text-xs md:text-2xl border-[2px] border-[#034A47] w-10 h-10 rounded-lg text-center md:w-[40px] md:h-[40px]   font-medium text-[#034A47] bg-white focus:border-[#034A47] focus:outline-none'
          />
        ))}
      </div>
    </form>
  )
}

export default OtpInputForm
