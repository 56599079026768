import React, { useMemo } from "react";

import { MatchedDoctor } from "../../types/User";
import { ArrowLeft, Heart, Star } from "react-feather";
import { DDGColors } from "../../constants/colors";
import { getRatings } from "../../functions/firebase";
import { Review } from "../../types/Consultation";
import ProviderAvailability from "./ProviderAvailability";
// import { format } from "date-fns";
import { convertDateToString, getInitials, getTimestampDate } from "../../functions";
import { useSelector } from "react-redux";
import { AppState } from "../../state";
import { useSwitchPage } from "../../state/features/applicationSlice/hooks";
import { ApplicationPages } from "../../state/features/applicationSlice";
import Image from "../Image";

interface DoctorsPageProps {
  activeDoctor: MatchedDoctor;
  doctorReviews: Review[];
}

const DoctorsPage: React.FC<DoctorsPageProps> = ({
  activeDoctor,
  doctorReviews,
}) => {
  const { activeBooking } = useSelector<
  AppState,
  AppState["application"]
>((state) => state.application);

  const doctorRating = useMemo(() => {
    return getRatings(doctorReviews);
  }, [doctorReviews]);
 const router = useSwitchPage()
  return (
    <main className=" flex flex-col relative bg-[#F6F9F9] h-full ">
      <div className="pt-6 pb-1">
        <div className="flex flex-row items-center justify-between pr-4">
          <div className="flex flex-row pr-4 space-x-2">
          <button
              onClick={() => {
                router(ApplicationPages.SPEAK_TO_DOCTOR);
              }}
              className="p-3 rounded-full"
            >
              <ArrowLeft color="#292D32" size={14} />
            </button>
            <div className="relative flex flex-row items-center justify-center flex-shrink-0 w-10 h-10 mr-2 text-sm text-white rounded-full cursor-pointer bg-primary">
              {activeDoctor?.profileImage ? (
                <Image
                useBase={false}
                  alt={activeDoctor?.firstName}
                  src={activeDoctor?.profileImage}
                  className="flex-1 w-full h-full rounded-full"
                />
              ) : (
                <p>
                  {getInitials(
                    activeDoctor?.firstName + " " + activeDoctor?.lastName
                  )}
                </p>
              )}
            </div>
            <div className="">
              <div className="">
                <div>
                  <p className="text-sm font-semibold text-[#101828] capitalize">
                    Dr. {activeDoctor?.firstName + " " + activeDoctor?.lastName}
                  </p>
                  <p className="text-[11px] text-[#344054]">
                    {activeDoctor?.specialtyData?.title}
                  </p>
                </div>
                <p className="text-[10px] text-gray-500 font-light">
                  {activeDoctor?.address?.city},{" "}
                  {activeDoctor?.address?.country}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center px-2 py-1 space-x-1 border border-gray-200 rounded-md bg-primaryLight">
            <Star
              fill={DDGColors.primary}
              color={DDGColors.primary}
              size={10}
            />{" "}
            <p className="text-xs">
              {doctorRating?.overallRating} ({activeDoctor?.appointmentCount})
            </p>
          </div>
        </div>
        <div className="px-4 pl-8 my-4">
          <div className="flex flex-row flex-wrap gap-1 mb-2">
            <div className="flex-row flex items-center justify-center px-2 py-1 rounded-md bg-[#D7FAF7]">
              <p className="text-[8px]  uppercase text-center text-primary">
                Available Today
              </p>
            </div>
            <div className="flex-row flex items-center px-2 py-1 rounded-md  bg-[#fece00] bg-opacity-20">
              <Star
                fill="#5C4C0D"
                size={10}
                className="!text-xs text-[#5C4C0D]"
              />
              <p className="text-[8px]  uppercase pl-1  text-center text-[#5C4C0D]">
                HIGHLY RATED
              </p>
            </div>
            <div className="flex flex-row items-center px-2 py-1 bg-red-100 rounded-md">
              <Heart fill={DDGColors.blood} strokeWidth={0} size={10} />
              <p className="text-[8px]  uppercase pl-1 text-center text-[rgb(186,40,19)]">
                LOYAL PATIENTS
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 pt-4 bg-white shadow-t-xl shadow-gray700 rounded-t-2xl ">
        <div className="px-4 pb-4 mb-4 space-y-1 border-b border-gray-100">
          <p className="text-[10px] font-semibold text-gray700">About</p>
          <p className="text-xs font-medium text-gray900">
            {activeDoctor?.bio}
          </p>
        </div>
        <div className="px-4 pb-4 mb-4 space-y-1 border-b border-gray-100">
          <p className="text-[10px] font-semibold text-gray700">
            Book an appointment
          </p>
          <div>
            {activeDoctor && (
              <ProviderAvailability activeDoctor={activeDoctor} />
            )}
          </div>
        </div>
        <div className="px-4 pb-4 mb-4 border-b border-gray-100">
          <p className="text-[10px] font-semibold text-gray700">
            Education & Background
          </p>
          <div className="mt-4">
            <div>
              <p className="text-xs font-medium text-gray900">
                Spoken Languages
              </p>
              <div className="flex flex-row items-center space-x-2">
                {activeDoctor?.languages?.map(
                  (
                    language:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal
                      | null
                      | undefined,
                    index: number
                  ) => (
                    <div
                      key={index}
                      className="flex flex-row items-center py-1 rounded-full "
                    >
                      <p className="text-xs capitalize text-gray900">
                        {language}
                      </p>
                      {index < activeDoctor.languages.length - 1 && (
                        <p className="pl-1 text-[#D0D5DD]">•</p>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
            {activeDoctor?.specialtyData?.board_council?.title && (
              <div>
                <p className="text-xs font-medium text-gray900">
                  Licensing Council
                </p>
                <div className="">
                  <p className="text-sm text-gray-700">
                    {activeDoctor?.specialtyData?.board_council.title}
                  </p>
                  <p className="text-xs font-bold uppercase text-primary">
                    (Certified and In Good Standing)
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="px-4 mb-20 space-y-1 border-b border-gray-100">
          <p className="text-[10px] font-semibold text-gray700">Reviews</p>
          <div>
            {doctorReviews
              .filter((review) => review.feedback)
              .map((review, index) => {
                return (
                  <div
                    key={index}
                    className={`items-start flex flex-col my-2 space-y-1 pb-2 ${
                      index <
                      doctorReviews.filter((review) => review.feedback).length -
                        1
                        ? "border-b border-[#D6D6D6] border-opacity-50"
                        : ""
                    }`}
                  >
                    <Star size={"small"} />
                    <p className="text-xs font-semibold text-dark">
                      {review.feedback}
                    </p>
                    <div className="flex flex-row items-center w-full space-x-2">
                      <p className="text-[10px] text-gray500">
                        {/* {formatDistanceToNow(
                          getTimestampDate(review.createdAt as any),
                          { includeSeconds: true, addSuffix: true }
                        )} */}
                      </p>
                      <p className="text-[3475467]">•</p>
                      {review.patientData?.name && (
                        <div className="flex flex-row items-center space-x-2">
                          <p className="text-[10px] text-gray900 flex-row flex font-bold ">
                            {review.patientData?.name}
                          </p>

                          <p className="text-[#475467]">•</p>
                          <p className="text-[10px] text-gray500">
                            Verified Patient
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="flex flex-1 px-4 mt-4 space-y-4"></div>
        {/* <div className="flex flex-row items-center py-2 mx-auto space-x-1 ">
                <p className="text-[9px] text-gray-700 ">Powered by</p> <Logo />
              </div> */}
      </div>

      <div
        style={{ backgroundColor: "rgba(246, 249, 249,0.5)" }}
        className={` ${activeBooking.startTime ? "flex" : "hidden"}
             duration-400 bottom-0 absolute px-4  w-full self-stretch  pb-16   text-dark `}
      >
        <button
          className="items-center justify-center w-full p-3 rounded-md bg-primary"
          onClick={async () => {
            router(ApplicationPages.START)
          }}
        >
          <p className="text-xs font-bold text-primaryLight">
            Book appointment for{" "}
            {/* {format(new Date(activeBooking?.startTime ?? 200), "h:mm a MMM do")} */}
            {convertDateToString(new Date(activeBooking.startTime ?? 0), true)}
          </p>
        </button>
      </div>
    </main>
  );
};
export default DoctorsPage;
