import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import "./output.css";
import 'react-toastify/dist/ReactToastify.css'

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const widgetDivs = document.querySelectorAll(
  ".drdogood-widget-root"
) as NodeListOf<HTMLElement>;
widgetDivs.forEach((div) => {
  return ReactDOM.createRoot(div).render(
    <React.StrictMode>
      <App
        apiData={div.dataset?.slug || ""}
        isPopUp={div.dataset?.popup || ""}
      />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
