import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ArrowLeft, Calendar, Loader } from "react-feather";
// import { format } from "date-fns";
import { TailSpin } from "react-loader-spinner";
import { DDGColors } from "../../../constants/colors";
import { AppState } from "../../../state";
import { getInitials } from "../../../functions";
import BookingSteps from "../../../components/BookingSteps";
import { useSwitchPage } from "../../../state/features/applicationSlice/hooks";
import { ApplicationPages } from "../../../state/features/applicationSlice";
import Image from "../../../components/Image";
interface startProps {}
const Start: React.FC<startProps> = ({}) => {
  const [isActive, setIsActive] = useState(false)
  const { activeBooking, activeDoctor } = useSelector<
    AppState,
    AppState["application"]
  >((state) => state.application);

const router = useSwitchPage()
useEffect(()=>{
  if(activeDoctor && activeBooking.startTime){
    setIsActive(true)
  }
},[activeDoctor,  activeBooking.startTime])
if(!isActive){
  return  <div style={{minHeight : 500}} className="relative flex items-center justify-center flex-col flex-grow w-full h-full max-w-lg mx-auto overflow-y-hidden min-h-[300px]"><TailSpin width="20"  color={DDGColors.primary} /></div>
}
  return (
    <main className="h-full flex flex-col bg-[#F6F9F9]">
      <div className="pt-6 pb-1">
        <div className="flex flex-row items-center justify-between pt-8 pb-4 pr-4 md:pt-4 ">
          <div className="flex flex-row items-center">
            <button
              onClick={() => {
                router(ApplicationPages.DOCTOR)
              }}
              className="p-3 rounded-full"
            >
              <ArrowLeft color="#292D32" size={14} />
            </button>
            <div className="relative flex flex-row items-center justify-center flex-shrink-0 w-10 h-10 mr-2 text-sm text-white rounded-full cursor-pointer bg-primary">
              {activeDoctor?.doctor?.profileImage ? (
                <Image
                useBase={false}
                  alt={activeDoctor?.doctor?.firstName}
                  src={activeDoctor?.doctor?.profileImage}
                  className="flex-1 w-full h-full rounded-full"
                />
              ) : (
                <p>
                  {getInitials(
                    activeDoctor?.doctor?.firstName + " " + activeDoctor?.doctor?.lastName
                  )}
                </p>
              )}
            </div>
            <div className="">
              <div className="">
                <div>
                  <p className="text-sm font-semibold capitalize text-gray900">
                    Dr. {activeDoctor?.doctor?.firstName + " " + activeDoctor?.doctor?.lastName}
                  </p>
                </div>
                <div className="flex flex-row items-center space-x-1">
                  <Calendar size={10} color={"#344054"} />
                  <p className="text-[11px] text-gray700">
                    {/* {format(new Date(activeBooking?.startTime ?? 200), 'ccc, MMM do \'at\' h:mm a' )} */}
                    {new Date(activeBooking.startTime ?? 0).toUTCString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 pt-4 space-y-4 bg-white shadow-t-xl shadow-gray700 rounded-t-2xl">
        <BookingSteps type="DOCTOR_BOOKING"/>
        <div className="flex flex-row items-center pt-1 pb-3 mx-auto space-x-1 ">
          {/* <p className="text-[10px] text-gray-500 ">Powered by</p> <Logo /> */}
        </div>
      </div>
    </main>
  );
};
export default Start;
