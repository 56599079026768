import React from 'react'
import { useErrorBoundary } from 'react-error-boundary'

const ErrorComponent: React.FC = () => {
  const { resetBoundary } = useErrorBoundary()

  return (
    <div>
      <div className='flex flex-col items-center justify-center w-full h-screen'>
        <div className='space-y-2 text-center'>
          <p className='text-xl font:semi-bold'>Something went wrong</p>
          <p className='text-xs text-gray-500'>Contact Support</p>
        </div>
        <button
          className='my-3'
          onClick={() => {
            resetBoundary()
          }}
        >
          Go Home
        </button>
      </div>
    </div>
  )
}

export default ErrorComponent
