import React, { useEffect, useState } from "react";
import { webBaseUrl } from "./constants";
import Image from "./components/Image";
import { ApplicationPages, setActivePage } from "./state/features/applicationSlice";
import { useDispatch } from "react-redux";
import { ChevronDown, X } from "react-feather";
import { DDGColors } from "./constants/colors";

interface mainProps {
  children: any;
}

const PopupLayout: React.FC<mainProps> = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
const dispatch = useDispatch()
  useEffect(() => {
    if (typeof window !== "undefined") {
      const modal = localStorage.getItem("modal");
      if (modal) {
        setOpenModal(true);
      } else {
        setOpenModal(false);
      }
    }
  }, [openModal]);

  const handleOpenModal = (key: boolean) => {
    if (key) {
      if (typeof window !== "undefined") {
        localStorage.setItem("modal", JSON.stringify(true));
      }
      setOpenModal(true);
    } else {
      localStorage.removeItem("modal");
      setOpenModal(false);
    }
  };
  return (

      <div className="" style={{ overflow: "hidden" }}>
        {openModal ? (
        <div
            style={{
              boxShadow:
                "0 20px 25px 20px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
            }}
            className="fixed md:mt-16 md:right-[20px] md:w-[400px] md:bottom-[84px] md:min-h-[500px] h-[min(704px, 100% - 104px)] flex flex-col md:max-h-[704px] m-0 overflow-y-hidden md:rounded-[16px] h-screen z-[99] max-w-[100vw]"
          >
            <button
              type="button"
              onClick={() => {handleOpenModal(false)
                dispatch(setActivePage(ApplicationPages.HOME))
              }}
              className="fixed top-0 left-0 z-40 bg-opacity-30 md:hidden"
              >
                <div className="flex items-center justify-center p-2 bg-lightBlood rounded-br-xl ">  <X size={14} color={DDGColors.blood} /></div>
              
            </button>

            {children}
          </div>
        ) : null}
          <div
          className={`fixed bottom-[20px] ${openModal ?'hidden md:block':'block'} right-[20px] z-[999999]`}
            // style={{ position: "fixed", right: isMobile ? 0 : "7%", bottom: 0 }}
          >
            <button
              onClick={() => {
                if(openModal){
                  dispatch(setActivePage(ApplicationPages.HOME))
                }
                handleOpenModal(!openModal)
               
              }}
              type="button"
              style={{
                boxShadow:
                  "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
              }}
              className={`flex items-center justify-center rounded-full h-[48px] w-[48px] bg-primary p-3`}
            >
            {openModal ? <ChevronDown color="white" />:<Image useBase src="/icons/calendar-add.svg" alt="" className="h-full"/>}
            </button>
          </div>
      </div>
  );
};

export default PopupLayout;
