import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import * as Sentry from "@sentry/browser";
// import Intercom, { IntercomContent, Space } from '@intercom/intercom-react-native';

import { setUser, signOutUser } from "./index";
import { AppDispatch } from "../..";
import { getUserData } from "../../../functions/firebase";
// import { setUserId } from "@amplitude/analytics-react-native";
// import { saveKey } from "functions";
// import Smartlook from "react-native-smartlook-analytics/lib/typescript/smartlook/Smartlook";
export default function Updater(): null {
  const dispatch = useDispatch<AppDispatch>();
  // keep dark mode in sync with the system
  useEffect(() => {

  try {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        // ...
        const userAccount = await getUserData(uid);
        if (!userAccount) {
          dispatch(signOutUser(null));
          return;
        }
        dispatch(setUser(userAccount));
        // setUserId(userAccount.uid);
        // Sentry.setUser({ id: userAccount.uid });
        // await saveKey("uid", userAccount.uid);
        // Smartlook.instance.user.setIdentifier(userAccount.uid);
        // Intercom.loginUserWithUserAttributes({email: user.email,userId:userAccount.uid})
      } else {
        dispatch(signOutUser(null));
        // await saveKey("uid", null);

        // User is signed out
        // ...
      }
    });
    return unsubscribe;
  } catch (error) {
  }
  }, [dispatch]);
  return null;
}
