import React from "react";
const imageBaseUrl = 'https://booking-widget-c213.vercel.app';
interface ImageProps {
  useBase: boolean;
  src: string;
  alt: string;
  className?: string;
  width?: number;
  height?: number
  loading?: "lazy" | "eager" | undefined
}

const Image: React.FC<ImageProps> = ({ useBase, src, alt, className = "",width, height,loading= undefined }) => {
  return (
    <img
      alt={alt}
      src={useBase ? `${imageBaseUrl}${src}`: src}
      width={width}
      height={height}
      className={className}
      loading={loading}
    />
  );
};
export default Image;
