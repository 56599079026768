// import { format } from "date-fns";
import { getAuth } from "firebase/auth";

import React, { useEffect, useMemo, useState } from "react";
import {
  Check,
  Clipboard,
  Delete,
  File,
  Loader,
  Plus,
  Tag,
  Trash,
  X,
} from "react-feather";
import { RotatingLines, TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MatchedDoctor } from "../../types/User";
import { AppState } from "../../state";
import { useApplyPromo, useOneTimePay, useRequestConsultation } from "../../state/features/authSlice/hooks";
import { useSwitchPage, useVerifyPayment } from "../../state/features/applicationSlice/hooks";
import { PromoCode } from "../../types";
import { getUserData } from "../../functions/firebase";
import { setUser } from "../../state/features/authSlice";
import { BookingSource } from "../../types/Consultation";
import { ResponseStatus } from "../../types/Requests/Status";
import { ApplicationPages, setActiveBooking } from "../../state/features/applicationSlice";
import errorcodes from "../../constants/errorcodes";
import { DDGColors } from "../../constants/colors";
import Image from "../Image";
import { convertDateToString } from "../../functions";

interface StepThreeProps {
  activeDoctor: MatchedDoctor;
  callbackFunc: ()=> void
}

const StepThree: React.FC<StepThreeProps> = ({ activeDoctor,callbackFunc }) => {
  const { User, Account, PaymentMethods } = useSelector<
    AppState,
    AppState["auth"]
  >((state) => state.auth);
  const { activeBooking } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );
  const urlParams = new URLSearchParams(window.location.search);
  const trxref = urlParams.get('trxref');
  const pay = useOneTimePay();
  const applyPromo = useApplyPromo();
  const verifyPayment = useVerifyPayment();
  const auth = getAuth();
  const requestConsultation = useRequestConsultation();
  const dispatch = useDispatch();
  const router = useSwitchPage();
  const [selectedMethod, setSelectedMethod] = useState("WALLET");
  const [promoCodeData, setPromoCodeData] = useState<PromoCode>();
  const [promoCode, setPromoCode] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [applying, setApplying] = useState(false);
  const [paying, setPaying] = useState(false);
  // const [addingCard, setAddingCard] = useState(false);
  const [payReference, setPayReference] = useState("");
  const appointmentCost = useMemo(() => {
    const providerPrice =
      activeDoctor?.enabledServices[activeBooking.visitType.value || "VIRTUAL"]
        .price; // BUG here, not taking into account the organization balance

    const appointmentPrice = providerPrice;
    const studentDiscountPrice =
      activeDoctor.accepts_students_package && User?.is_student
        ? appointmentPrice * 0.7
        : 0;
    const promoDiscountPrice = promoCodeData
      ? promoCodeData.perk.perk_amount * appointmentPrice
      : 0;
    const discountedPrice = promoCodeData
      ? promoDiscountPrice
      : studentDiscountPrice;
    return {
      providerPrice: appointmentPrice,
      discountedPrice,
    };
  }, [promoCodeData, User]);
  const fetchData = async () => {
    const userAccount = await getUserData(Account?.uid ?? "");
    dispatch(setUser(userAccount));
  };
  const topUp = (amount: number, channels?: string[]) => {
    setPaying(true);
    // call pay func
    pay(
      {
        amount: (100 * amount + 1).toFixed(2),
        email: auth?.currentUser?.email,
        patientId: auth?.currentUser?.uid,
        ...(channels && { channels }),
      },
      (ref: any) => setPayReference(ref)
    );
  };
  const requestConsultationFunc = async (ignoreLoading?: boolean) => {
    if (!ignoreLoading && loading) return;
    setLoading(true);

    const consultationData = {
      type: "G-H-C",
      source: BookingSource.BOOKING_PAGE,
      doctorData: {
        memberId: activeDoctor.memberID,
        id: activeDoctor?.uid ?? activeDoctor.id,
        email: activeDoctor?.email,
        name: activeDoctor?.firstName + " " + activeDoctor?.lastName,
        institutionId:
          activeDoctor.practicies?.find((practice) => practice.is_primary)
            ?.institutionId ?? "",
      },
      patientData: {
        id: Account?.uid || "",
        email: User?.email || "",
        name: User?.firstName + " " + User?.lastName,
        memberId: User?.memberID ?? "",
      },
      complaints: activeBooking.complaints,
      startTime: new Date(activeBooking?.startTime ?? 0),
      medium: activeBooking.visitType?.value,
      ...(promoCodeData && { promoCodeId: promoCodeData?.id }),
      payment_method: selectedMethod,
      
    };

    requestConsultation(
      consultationData,
      async ({ status, code, message, data }: any) => {
        if (status === ResponseStatus.SUCCESS) {
          
          setLoading(false);
          fetchData();
          urlParams.set("time", activeBooking.startTime?.toString() ?? '0')
          urlParams.set("type", activeBooking.visitType.value)
          urlParams.set("cid", data.cid)
          urlParams.set("aid", data.aid)

          window.history.pushState(null,'', "?"+ urlParams.toString());
          router(ApplicationPages.SUCCESS)
          dispatch(
            setActiveBooking({
              visitType: {
                name: "Video/Virtual visit",
                value: "VIRTUAL",
              },
              complaints: "",
              startTime: null,
              activeInterval: null,
            })
          );
        }
        if (code === errorcodes.insuficientCredits) {
          topUp(data.data.amount);
        }
        if (data.code === errorcodes.unavailableTimeSlot) {
          setPaying(false);
          setLoading(false);
        }
        if (data.code === errorcodes.errorChargingMethod) {
          setPaying(false);
          setLoading(false);
        }
        if (message === "account disabled from booking") {
          setPaying(false);
          setLoading(false);
        }
      }
    );
  };
  const continueFunc = () => {
    if (selectedMethod === "mobile_money" || selectedMethod === "card") {
      setLoading(true);
      topUp(
        parseFloat(
          (
            appointmentCost.discountedPrice || appointmentCost.providerPrice
          )?.toFixed(2)
        ),
        [selectedMethod]
      );
    } else {
      requestConsultationFunc();
    }
  };
  const verifyTransaction = async (txref: string) => {
    const paid = await verifyPayment(txref);
    if (paid) {
      setSelectedMethod("WALLET");
      requestConsultationFunc(true);
    } else {
      setLoading(false);
      toast.error("Unable to verify payment");
    }
    urlParams.delete('trxref')
    setPaying(false);
  };
  useEffect(() => {
    if (trxref) {
      verifyTransaction(trxref as string);
    }
  }, [activeDoctor, trxref]);
  if (!Account) {
    return <></>;
  }
  return<div className="flex flex-col flex-1 space-y-4 overflow-y-scroll scroll">
  <div className="px-2 ">
    <p className="text-lg font-bold text-gray900">Confirm Your Booking</p>
    <p className="text-xs text-gray500">
      You&apos;ll receive a video call link on
      your phone for the consultation after booking.
    </p>
  </div>
  <div className=" mx-2 border-[#D6D6D6] bg-[#F6F6F6] border-opacity-20 flex flex-col h-28 px-4 py-2 rounded-md border">
    <div className="flex flex-row items-center mb-1 space-x-1">
      <Clipboard size={10} />
      <p className="text-xs font-semibold text-gray700">
        Reason for appointment:
      </p>
    </div>
    <textarea
      value={activeBooking.complaints}
      onChange={(e) => {
        dispatch(
          setActiveBooking({ ...activeBooking, complaints: e.target.value })
        );
      }}
      placeholder="Describe your symptoms and when they started (e.g., fever for 3 days, persistent cough"
      className="flex-1 w-full text-xs bg-transparent outline-none text-gray500"
    />
  </div>
  <div className="flex-1 px-2 ">
    <p className="text-[10px] font-semibold text-gray700">
      Select your payment method
    </p>
    <div className="relative flex flex-col mt-4 space-y-4">
      {Account.balance.value >=
        (appointmentCost.discountedPrice ||
          appointmentCost.providerPrice) && (
        <button
          onClick={() => {
            setSelectedMethod("WALLET");
          }}
          className="flex flex-row items-center w-full space-x-3 "
        >
          <div
            className={`self-start flex items-center justify-center w-5 h-5 rounded-full ${
              selectedMethod === "WALLET"
                ? "bg-primary"
                : "border-2 border-gray-300 cursor-pointer"
            }`}
          >
            {selectedMethod === "WALLET" ? (
              <Check size={12} color={DDGColors.primaryLight} />
            ) : (
              <></>
            )}
          </div>
          <div className="flex-row  flex items-center justify-between flex-1  border-opacity-40 border-b pb-3 border-[#D6D6D6]">
            <div className="flex flex-col items-start flex-1 ">
              <p className="font-semibold text-gray900 text-xs mb-0.5">
                DDG Balance
              </p>
              <p className="text-[11px] text-gray500">
                ₵
                {(
                  Account.balance.value + (Account.balance?.org_value ?? 0)
                ).toFixed(2)}
              </p>
            </div>
            <div className="items-center flex justify-center rounded-full p-1.5 bg-primaryLight">
              <Image
                useBase
                alt="wallet"
                src={"/img/empty-wallet.svg"}
                width={16}
                height={16}
              />
            </div>
          </div>
        </button>
      )}
      <button
        onClick={() => {
          setSelectedMethod("mobile_money");
        }}
        className="flex flex-row items-center w-full space-x-3 "
      >
        <div
          className={`self-start flex items-center justify-center w-5 h-5 rounded-full ${
            selectedMethod === "mobile_money"
              ? "bg-primary"
              : "border-2 border-gray-300 cursor-pointer"
          }`}
        >
          {selectedMethod === "mobile_money" ? (
            <Check size={12} color={DDGColors.primaryLight} />
          ) : (
            <></>
          )}
        </div>
        <div className="flex-row  flex items-center justify-between flex-1  border-opacity-40 border-b pb-3 border-[#D6D6D6]">
          <div className="flex flex-col items-start flex-1 ">
            <p className="text-xs font-semibold text-gray900 ">
              Mobile Money
            </p>
          </div>
          <div className="">
            <Image
            useBase
              width={100}
              height={20}
              alt="wallet"
              src={"/img/momo.svg"}
              className="object-contain"
            />
          </div>
        </div>
      </button>
      <button
        onClick={() => {
          setSelectedMethod("card");
        }}
        className="flex flex-row items-center w-full space-x-3 "
      >
        <div
          className={`self-start flex items-center justify-center w-5 h-5 rounded-full ${
            selectedMethod === "card"
              ? "bg-primary"
              : "border-2 border-gray-300 cursor-pointer"
          }`}
        >
          {selectedMethod === "card" ? (
            <Check size={12} color={DDGColors.primaryLight} />
          ) : (
            <></>
          )}
        </div>
        <div className="flex-row  flex items-center justify-between flex-1  border-opacity-40 border-b pb-3 border-[#D6D6D6]">
          <div className="flex flex-col items-start flex-1 ">
            <p className="text-xs font-semibold text-gray900 ">Bank Card</p>
          </div>
          <div className="">
            <Image
            useBase
              width={50}
              height={20}
              alt="wallet"
              src={"/img/cards.png"}
              className="object-contain"
            />
          </div>
        </div>
      </button>
      {[
        ...[...PaymentMethods].map((method, index) => {
          return {
            title: `Card ending in ${method.last4}`,
            card_type: method.card_type,
            id: method.id,
            icon: (
              <Image
              useBase
                src={
                  method.card_type.trim() === "visa"
                    ? "/img/visa-logo.svg"
                    : "/img/visa-logo.svg"
                }
                alt="card"
                width={100}
                height={20}
                className="object-contain"
              />
            ),
          };
        }),
      ].map((item, index) => {
        return (
          <button
            key={index}
            onClick={() => {
              setSelectedMethod(item.id);
            }}
            className="flex flex-row items-center w-full space-x-3 "
          >
            <div
              className={`self-start flex items-center justify-center w-5 h-5 rounded-full ${
                selectedMethod === item.id
                  ? "bg-primary"
                  : "border-2 border-gray-300 cursor-pointer"
              }`}
            >
              {selectedMethod === item.id ? (
                <Check size={12} color={DDGColors.primaryLight} />
              ) : (
                <></>
              )}
            </div>
            <div className="flex-row  flex items-center justify-between flex-1  border-opacity-40 border-b pb-3 border-[#D6D6D6]">
              <div className="flex flex-col items-start flex-1 ">
                <p className="text-xs font-semibold text-gray900 ">
                  {item.title}
                </p>
              </div>
              <div className="">{item.icon}</div>
            </div>
          </button>
        );
      })}
      {/* <button
    onClick={() => {}}
    className="flex flex-row items-center w-full space-x-3 "
  >
    <div className="items-center flex justify-center rounded-full p-1.5 bg-primaryLight">
      <Plus size={12} color={DDGColors.primary} />
    </div>
    <div className="flex flex-row items-center justify-between flex-1 ">
      <div className="flex flex-col items-start flex-1 ">
        <p className="text-xs font-semibold text-gray900 ">
          Add new card
        </p>
      </div>
    </div>
  </button> */}
    </div>
  </div>
  <div
    className={`
px-2 pt-2 
w-full  text-dark  border-t-2 border-gray-50`}
  >
    <div className="">
      {!activeDoctor?.accepts_perks && (
        <div className="flex flex-row items-center ">
          <div className="flex items-center justify-center w-10 h-10 mr-4 rounded-full ">
            <Tag size={12} fill={DDGColors.primary} color="#034a47" />
          </div>
          <div className="flex flex-row items-center justify-between flex-1 py-4">
            <div className="flex flex-col self-stretch justify-center ">
              <div className="flex flex-row items-center justify-between">
                <input
                  value={promoCode}
                  placeholder="Got a promo code ?"
                  onChange={(e) => setPromoCode(e.target.value)}
                  disabled={Boolean(promoCodeData)}
                  className="text-sm outline-none "
                />
                {promoCode && !promoCodeData && (
                  <button
                    onClick={() => {
                      setPromoCode("");
                    }}
                    className="p-1 bg-gray-200 rounded-full"
                  >
                    <X size={14} />
                  </button>
                )}
              </div>
              {promoCodeData && (
                <p className="text-xs">{promoCodeData.description}</p>
              )}
            </div>
            {promoCode && promoCode.length >= 4 && (
              <button
                onClick={() => {
                  setApplying(true);
                  applyPromo(promoCode.trim(), async (val: any) => {
                    setApplying(false);

                    if (val.status === "FAILED") {
                      toast.error(val.message);
                    } else {
                      toast.success(val.message);
                    }
                    if (val.promoCodeData) {
                      setPromoCodeData(val.promoCodeData);
                    }
                    if (val.status !== "FAILED") {
                      fetchData();
                    }
                  });
                }}
                disabled={!promoCode || applying || loading}
              >
                {promoCodeData ? (
                  <button
                    className="p-2"
                    onClick={() => {
                      setPromoCodeData(undefined);
                    }}
                  >
                    <Trash
                      fill={DDGColors.blood}
                      size={16}
                      color={DDGColors.blood}
                    />
                  </button>
                ) : applying ? (
                  <TailSpin width={20} height={20} color={DDGColors.primary}/>
                ) : (
                  <p
                    className={`${
                      promoCode ? "text-primary" : "text-dark"
                    } text-xs font-bold`}
                  >
                    Apply
                  </p>
                )}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
    <div className="flex flex-row items-end justify-between">
      <div className="w-1/2 ">
        {Account?.perks?.consultation[activeBooking.visitType?.value] > 0 &&
        activeDoctor?.accepts_perks ? (
          <p className="font-bold text-md text-primary">
            You have{" "}
            {Account?.perks?.consultation[activeBooking.visitType?.value]}{" "}
            free {activeBooking.visitType?.value} consultation(s)
          </p>
        ) : (
          <p className="text-sm font-bold text-primary">
            ₵
            {(
              appointmentCost.discountedPrice ||
              appointmentCost.providerPrice
            )?.toFixed(2)}
          </p>
        )}
        <p className="text-xs font-bold">
          {/* {format(
            new Date(activeBooking?.startTime ?? 0),
            "ccc MMM do '-' h:mm a"
          )} */}
          {/* {new Date(activeBooking.startTime ?? 0).toUTCString()} */}
          {convertDateToString(new Date(activeBooking.startTime ?? 0), true)}

        </p>
        <p className="text-[10px] text-gray-400 font-light">
          Free cancellation*
        </p>
      </div>
      <div className="w-1/2 pl-3">
        <button
          className="flex items-center justify-center w-full py-3 rounded-md bg-primary"
          // disabled={loading}
          onClick={() => {
            if (
              activeBooking?.startTime &&
              new Date(activeBooking?.startTime) < new Date()
            ) {
              toast.error("Appointment time ellapsed, select a new time");
              // navigation.back();
              return;
            }
            if (promoCode && !promoCodeData) {
              alert("You have an unapplied promo code");
            } else {
              continueFunc();
            }
          }}
        >
          {loading ? (
            <RotatingLines width="20"  strokeColor={DDGColors.gainsboro} />
          ) : (
            <p className="text-xs font-semibold text-white">Continue</p>
          )}
        </button>
      </div>
    </div>
  </div>
</div>
};

export default StepThree;
